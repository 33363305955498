import React  from 'react';
import {Col, Divider, Popconfirm, Row, Typography} from "antd";
import {PATHS, TAB_POLL_PATH} from "../../constans/Paths";
import useCreateBreadcrumbs from "../../hooks/useCreateBreadcrumbs";
import Button from "../../components/data_entry/Button";
import FormBuild from "../../components/data_entry/form_field/FormBuild";
import {BuildFieldErrorsDict, formatPath} from "../../utils/Utils";
import api from "../../services/api";
import {ToastNotification} from "../../components/feedback/ToastNotification";
import {useNavigate} from "react-router-dom";
import usePollForm from "./hooks/usePollForm";
import {API_PATHS} from "../../constans/ApiPaths";


const breadcrumbs = [
    {
        title: 'Pesquisas',
        link: PATHS.POLL
    },
    {
        title: 'Criar nova pesquisa',
    },

]


export default function PageCreatePoll() {
    useCreateBreadcrumbs(breadcrumbs)

    const {
        isLoadingSelects,
        formItems,
        getValuesForm,
        form,
        setFormIsLoading,
        formIsLoading
    } = usePollForm()


    const navigate = useNavigate()


    async function handleSubmit() {
        try {
            setFormIsLoading(true)
            let data = await getValuesForm()
            const response = await api.post(API_PATHS.POLL, data);
            ToastNotification('Pesquisa criada com sucesso')
            navigate(formatPath(PATHS.UPDATE_POLL, {id: response.data.id,  tab_id: TAB_POLL_PATH.SERVERS}))

        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, false)
        } finally {
            setFormIsLoading(false);
        }
    }


    return (
        <>
            <Typography.Title>Criar nova pesquisa</Typography.Title>
            <Divider/>
            <FormBuild loading={isLoadingSelects()} onFinish={handleSubmit}
                // onValuesChange={handleFields}
                       fields={formItems} form={form}
                       footer={
                           <Row justify={'end'} gutter={[8, 8]}>
                               <Col>
                                     <Popconfirm
                                       title={`Cancelar cadastro`}
                                       description="Tem certeza que deseja realizar está ação?"
                                       onConfirm={() => navigate(PATHS.POLL)}
                                       okText="Sim"
                                       okButtonProps={{loading: formIsLoading}}
                                       cancelText="Cancelar"
                                   >
                                       <Button loading={formIsLoading}
                                           type={"default"}>Cancelar</Button>
                                   </Popconfirm>

                               </Col>
                               <Col>
                                   <Button loading={formIsLoading}
                                           htmlType="submit">Salvar</Button>
                               </Col>
                           </Row>
                       }/>

        </>
    );
}
