import React from 'react';
import QuestionContainer from "./QuestionContainer";




export default function QuestionEspontane({ ...props}) {
    return (
        <QuestionContainer {...props} />
    )
}
