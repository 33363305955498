import styled from 'styled-components';
import {Layout, Menu as MenuAnt, Typography} from "antd";
import React from "react";
import CollapsedMenuButton from "./CollapsedMenuButton";
import useLayoutContent from "../../context/LayoutContext";
import LogoImage from "../../assests/img/vertical_logo.png";
import CircularLogo from "../../assests/img/logo_circular_1.png";
import {
    UserOutlined, SnippetsOutlined
} from "@ant-design/icons";
import {red} from "../../utils/Colors";
import {PATHS} from "../../constans/Paths";
import {NavLink} from "react-router-dom";
import {FlexContainer} from "../other/StyledComponents";

const {Sider: SiderAnt} = Layout;

const Sider = styled(SiderAnt)`
  background: white !important;

  ${({float}) => {
    if (float) {
      return `
          &.ant-layout-sider {
            position: absolute !important;
            left: 0;
            top: 0;
            z-index: 9999;
            box-shadow: 11px 0px 15px  rgba(0,0,0,0.1);
            
        
            .ant-layout-sider-children {
              height: 100vh !important;
        
            }
          }
          `
    }
  }}

`

const Header = styled.div`
  background: ${red};
  padding: 8px 16px;
  height: var(--header-height);
  overflow: hidden;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: ${({collapsed}) => collapsed ? 'center' : 'space-between'};
`

const Logo = styled.img`
  height: ${({height}) => height ? `${height}px` : '40px'};
`

const menuOptions = [

    {
        key: 'pesquisa',
        label: <NavLink to={PATHS.POLL}>Pesquisas</NavLink>,
        icon: <SnippetsOutlined/>
    },
    {
        key: 'Usuários',
        label: <NavLink to={PATHS.USER}>Usuários</NavLink>,
        icon: <UserOutlined/>
    },

]


export default function Menu(props) {

    const {collapsed, floatMenu} = useLayoutContent();


    function getOptions() {
        return menuOptions.filter((object) => object.hasOwnProperty('permission') ? object.permission : true)
    }


    return (

        <Sider
            collapsible
            trigger={null}
            float={floatMenu}
            collapsedWidth={floatMenu ? 0 : 80}
            collapsed={collapsed}
            {...props}>
            <Header collapsed={collapsed}>
                {/*<span>falta logo</span>*/}
                {collapsed ? <Logo height={25} src={CircularLogo}/> :
                    <FlexContainer gap={8}><Logo src={LogoImage}/> <Typography.Title style={{color: 'white'}} level={5}>CodSearch</Typography.Title></FlexContainer>}
                <CollapsedMenuButton/>
            </Header>
            <MenuAnt
                // theme="dark"
                // openKeys={['cadastros']}
                mode="inline"
                collapsed={false}//todo usar setstate
                // selectedKeys={defaultSelectedKeys}
                items={getOptions()}/>

        </Sider>

    );
}
