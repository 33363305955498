import React, {useMemo, useState} from 'react';
import {Modal, Form as FormAnt, Row, Col, Form, Typography} from "antd";
import FormBuild, {
    FIELDS_NAMES
} from "../../../../components/data_entry/form_field/FormBuild";
import {
    generateSelectOptions
} from "../../../../utils/Utils";
import {BAR, GRAPHICS_PRINT_CHOICES} from "../../../../constans/choices";
import {Margin, usePDF} from "react-to-pdf";
import IntroductionResult from "./IntroductionResult";
import Table from "../../../../components/data_display/Table";
import styled from 'styled-components'
import Bar from "../../../../components/data_display/graphics/Bar";
import Pie from "../../../../components/data_display/graphics/Pie";
import {FlexContainer} from "../../../../components/other/StyledComponents";
import Button from "../../../../components/data_entry/Button";

const FORM_NAMES = {
    GRAPHICS: 'graphics',
    HAS_TABLE: 'has_table',
    HAS_INTRODUCTION: 'has_introduction',

}

const Page = styled.div`
    width: 210mm;
    height: 297mm !important;
    padding: 16px 20px;
`

const Introduction = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;

    span {
        font-size: 24px;
    }


`

export default function ResultPrintModal({poll, results, open, setOpen}) {
    const [form] = FormAnt.useForm()
    const [isLoading, setIsLoading] = useState()
    const has_introduction_value = FormAnt.useWatch(FORM_NAMES.HAS_INTRODUCTION, form)
    const has_table_value = FormAnt.useWatch(FORM_NAMES.HAS_TABLE, form)
    const graphic_value = FormAnt.useWatch(FORM_NAMES.GRAPHICS, form) || []

    const {toPDF, targetRef} = usePDF({
        method: "save",
        filename: `${poll.title}.pdf`,
        page: {margin: Margin.NONE},
    });

    async function handleSubmit() {
        try {
            setIsLoading(true)
            toPDF()
        } catch (error) {
        } finally {
            setTimeout(() => {
                 setIsLoading(false)
            },  3000)

        }

    }

    function handleClose() {
        setOpen(false)
        form.resetFields()
    }

    const formItems = [

        // {
        //     field_type: FIELDS_NAMES.CHECKBOX,
        //     col: {xs: 12},
        //     form: {
        //         label: 'Adicionar introdução?',
        //         name: FORM_NAMES.HAS_INTRODUCTION,
        //
        //     },
        // },

        // {
        //     field_type: FIELDS_NAMES.CHECKBOX,
        //     col: {xs: 12},
        //     form: {
        //         label: 'Adicionar tabela?',
        //         name: FORM_NAMES.HAS_TABLE,
        //
        //     },
        // },
        {
            field_type: FIELDS_NAMES.CHECKBOX_GROUP,
            col: {xs: 24},
            options: generateSelectOptions(GRAPHICS_PRINT_CHOICES, 'value'),
            form: {
                label: 'Tipo de grafico?',
                name: FORM_NAMES.GRAPHICS,
                rules: [{
                    required: true,
                    message: 'Adicione no minimo 1.'
                }]
            },
        },


    ]
    const initialValues = useMemo(() => ({
        [FORM_NAMES.HAS_INTRODUCTION]: true,
        [FORM_NAMES.HAS_TABLE]: true,
        [FORM_NAMES.GRAPHICS]: [BAR],
    }), [])
    if (!open) return null
    return (

        <Modal
            open={open}
            title="Gerar PDF"
            width={800}
            okButtonProps={{
                loading: isLoading
            }}
            okText={'Gerar PDF'}
            onOk={form.submit}
            onCancel={handleClose}

        >
            <Row>
                <Col xs={24}>
                    <FormBuild
                        initialValues={initialValues}
                        onFinish={handleSubmit}
                        fields={formItems} form={form}
                    />
                </Col>
                <Col xs={24}>
                    <Button onClick={form.submit} loading={isLoading}>Gerar
                        PDF</Button>
                </Col>
                <Col xs={24}>
                    <Typography.Title level={4}>Visualização</Typography.Title>
                    <div style={{
                        // padding: 16,
                        border: '1px solid #f0f0f0',
                        borderRadius: 16,
                        overflow: "scroll"
                    }}>
                        <div ref={targetRef} style={{width: '210mm'}}>
                            {/*<Row>*/}
                            {/*    {has_introduction_value && <Col xs={24}>*/}
                            {/*        <Page>*/}
                            {/*            <Introduction>*/}
                            {/*                <IntroductionResult poll={poll}/>*/}
                            {/*            </Introduction>*/}
                            {/*        </Page>*/}
                            {/*    </Col>}*/}


                            {/*<Col xs={24}>*/}
                            {
                                results?.map((result, index) => (
                                    <>
                                        {/*{has_table_value &&*/}
                                        {/*    <Page>*/}
                                        {/*        <FlexContainer*/}
                                        {/*            style={{height: '100%'}}*/}
                                        {/*            direction={'column'}*/}
                                        {/*            align={'start'}>*/}
                                        {/*            <Typography.Title*/}
                                        {/*                level={5}>{result.question.sequence} )*/}
                                        {/*                - {result.question.statement}</Typography.Title>*/}

                                        {/*            <Table*/}
                                        {/*                dataSource={result.results}*/}
                                        {/*                // tableParams={tableParams}*/}
                                        {/*                noPagination*/}
                                        {/*                noScroll*/}
                                        {/*                scroll={{x: '100%'}}*/}
                                        {/*                // onChange={handleTableChange}*/}
                                        {/*                columns={[*/}
                                        {/*                    {*/}
                                        {/*                        title: 'Resposta',*/}
                                        {/*                        dataIndex: 'statement',*/}
                                        {/*                        key: 'statement',*/}
                                        {/*                        width: 500*/}
                                        {/*                    }, {*/}
                                        {/*                        title: 'Porcentagem %',*/}
                                        {/*                        dataIndex: 'percent',*/}
                                        {/*                        key: 'percent',*/}
                                        {/*                        render: (value) => `${value}%`*/}
                                        {/*                    },*/}
                                        {/*                ]}/>*/}
                                        {/*        </FlexContainer>*/}
                                        {/*    </Page>}*/}
                                        {graphic_value.includes('bar') &&
                                            <Page>
                                                <FlexContainer
                                                    style={{height: '100%'}}
                                                    direction={'column'}
                                                    align={'start'}>
                                                    <Typography.Title
                                                        level={5}>{result.question.sequence} )
                                                        - {result.question.statement}</Typography.Title>


                                                    <Bar poll={poll}
                                                         hiddeLegend
                                                         data={result.results}/>


                                                </FlexContainer>

                                            </Page>
                                        }
                                        {graphic_value.includes('pie') &&
                                            <Page>
                                                <FlexContainer
                                                    style={{height: '100%'}}
                                                    direction={'column'}
                                                    align={'start'}>
                                                    <Typography.Title
                                                        level={5}>{result.question.sequence} )
                                                        - {result.question.statement}</Typography.Title>


                                                    <Pie poll={poll}
                                                         data={result.results}/>


                                                </FlexContainer>

                                            </Page>

                                        }


                                    </>

                                ))
                            }


                        </div>
                    </div>

                </Col>
            </Row>

        </Modal>

    )
        ;
}
