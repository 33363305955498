import React from 'react';
import InputField from "../InputField";
import {Form} from "antd";
import TextAreaField from "../TextAreaField";
import FormItem from "./FormItem";

export default function TextAreaFormField({form, ...props}) {
    return (
        <FormItem {...form}>
            < TextAreaField
                {...props}
            />
        </FormItem>
    )
}