import React, { useRef, useState} from 'react';
import {Card,} from "antd";
import styled from 'styled-components'
import DragContainter from "./DragContainter";
import {v4 as uuid} from "uuid";


const StyledCard = styled(Card)`

  .ant-card-body {
    overflow-y: scroll;
    max-height: 500px;
    padding: 0px ;
    transition: 0.2s;
    background-color: ${({startDragQuestion}) => startDragQuestion ? 'aliceblue' : 'transparent'};
      // padding-top: ${({startDragQuestion}) => startDragQuestion ? '48px' : '24px'};
      // padding-bottom: ${({startDragQuestion}) => startDragQuestion ? '48px' : '24px'};
  }

`


export default function DroppableForm({
                                          questions,
                                          setQuestions,
                                          setStartDragQuestion,
                                          startDragQuestion,
                                          poll
                                      }) {


    const [addPosition, setAddPosition] = useState({
        sequence: 1,
        position: 0
    })
    const currentElement = useRef()


    function handleDropContainer(e) {

        const currentSequence = Number(e.dataTransfer.getData('currentSequence')) || -1
        const currentIndex = currentSequence > 0 ? currentSequence - 1 : 0
        const newQuestions = [...questions]
        const type = e.dataTransfer.getData('type')
        // console.log(currentSequence, 'sequence')
        // console.log(currentIndex, 'index')
        // console.log(addPosition.position, 'position')
        let question = currentSequence > 0 ? newQuestions[currentIndex] : {
            key: uuid(),
            statement: "",
            sequence: 0,
            type: type
        };
        if (currentSequence !== addPosition.position) {
            currentSequence !== -1 && newQuestions.splice(currentIndex, 1)

            newQuestions.splice(addPosition.position, 0, question)
            setQuestions(recalculateSequence(newQuestions))


            setQuestions(recalculateSequence(newQuestions))
        }
        setStartDragQuestion(false)

    }

    function recalculateSequence(questions) {

        return questions.map((question, index) => ({
            // id: question
            ...question,
            sequence: index + 1
        }))
    }

    function handleDragOverContainer(e) {
        e.preventDefault()

        if (!startDragQuestion) return
        const element = e.target.closest('[data-question-sequence]')
        if (!element) return

        const index = element.getAttribute('data-question-sequence')
        const newPosition = {sequence: Number(index), position: 0}

        if (index) {


            const is_top = e.nativeEvent.offsetY < element.offsetHeight / 2
            if (is_top) {
                newPosition['position'] = newPosition['sequence'] - 1
                element.classList.add('top')
                element.classList.remove('bottom')
            } else {
                newPosition['position'] = newPosition['sequence']
                element.classList.add('bottom')
                element.classList.remove('top')
            }
            if (currentElement.current !== element) {
                if (currentElement.current) {

                    currentElement.current.classList.remove('top')
                    currentElement.current.classList.remove('bottom')
                }
                currentElement.current = element
            }
            setAddPosition(newPosition)
        }
    }


    function handleDragStart(e) {
        setStartDragQuestion(true)
    }

    function handleDragEnd(e) {
        setStartDragQuestion(false)
    }


    return (

        <StyledCard
            title="Perguntas"
            startDragQuestion={startDragQuestion}
        >

            <DragContainter recalculateSequence={recalculateSequence}
                            questions={questions}
                            onDragEnd={handleDragEnd}
                            onDragStart={handleDragStart}
                            setQuestions={setQuestions}
                            onDrop={handleDropContainer}
                            onDragOver={handleDragOverContainer}/>
        </StyledCard>


    );
}
