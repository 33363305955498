import React, {useRef, useState} from 'react';
import QuestionContainer from "./QuestionContainer";
import {Alert, Col, Popconfirm, Row, Space, Typography} from "antd";
import Button from "../../../../components/data_entry/Button";
import {DeleteOutlined} from "@ant-design/icons";
import styled from 'styled-components'
import {v4 as uuid} from "uuid";
import InputFormField
    from "../../../../components/data_entry/form_field/InputFormField";

const DeleteContainer = styled.div`
    font-size: 18px;
    padding: 2px 8px;
    cursor: pointer;

`

export default function QuestionInduziada({onChange, question, ...props}) {

    const [alternatives, setAlternatives] = useState(question.alternatives ?? [{
        statement: "",
        key: uuid()
    }])
    const hasChangeRef = useRef(false)

    function handleAddAlternative() {
        setAlternatives([...alternatives, {statement: "", key: uuid(),}])
    }

    function handleBlurAlternative({target: {value}}, index) {
        if (hasChangeRef.current) {
            onChange({...question, alternatives: alternatives})
            hasChangeRef.current = false
        }
    }

    function handleOnChange({target: {value}}, index) {
        const newAlternatives = [...alternatives]
        newAlternatives[index].statement = value
        setAlternatives(newAlternatives)
        hasChangeRef.current = true
    }

    function handleDelete(index) {
        const newAlternatives = alternatives.filter((alternative, internalIndex) => internalIndex !== index)
        setAlternatives(newAlternatives)
        onChange({...question, alternatives: newAlternatives})
    }

    return (
        <QuestionContainer onChange={onChange} question={question} {...props} >
            <Row gutter={[8, 8]}>
                <Col xs={24}><Typography.Title
                    level={5}>Alternativas</Typography.Title></Col>


                {alternatives.map((alternative, index) => {
                    const name = alternative.id || alternative.key
                    return (
                        <Col xs={24} key={name}>
                            <Row>
                                <Col>
                                    <Space size={4}>
                                        <span>{index + 1} -</span>
                                        <InputFormField
                                            form={
                                                {
                                                    initialValue: alternative.statement,
                                                    name: name,
                                                    rules: [{
                                                        required: true,
                                                        message: 'Essa informação é obrigatória'
                                                    }]
                                                }
                                            }
                                            onChange={(e) => handleOnChange(e, index)}
                                            defaultValue={alternative.statement}
                                            onBlur={(e) => handleBlurAlternative(e, index)}/>
                                    </Space>
                                </Col>
                                <Col>
                                    <Popconfirm
                                        title={`excluir item`}
                                        description="Tem certeza que deseja realizar está ação?"
                                        onConfirm={() => handleDelete(index)}
                                        okText="Sim"
                                        cancelText="Cancelar"Criar nova pesquisa
                                    >
                                        <DeleteContainer><DeleteOutlined/></DeleteContainer>
                                    </Popconfirm>
                                </Col>
                            </Row>
                        </Col>
                    )
                })}
                {alternatives.length > 10 && <Col xs={24}>
                    <Alert
                        message="Ao inserir mais de 10 (Dez) alternativas poderá haver um problema em alguns gráficos do relatórios !"
                        type="warning" showIcon/>
                </Col>}
                <Col xs={24}>
                    <Button onClick={handleAddAlternative}>Nova
                        alternativa</Button>
                </Col>


            </Row>
        </QuestionContainer>
    )
}

