import React, { useState} from 'react';
import {Col, Row} from "antd";
import {PlusOutlined} from '@ant-design/icons'
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import useTable from "../../hooks/useTable";
import DevTools from "./DevTools";
import Search from "../data_entry/Search";
import Button from "../data_entry/Button";
import Table from "../data_display/Table";

export default function DefaultListPage({table, createButton}) {
    // const [bodyRequest, setBodyRequest] = useState()
    const {lg} = useBreakpoint();
    const [search, setSearch] = useState();

    function getFlexGrowByGrid() {
        return !lg ? 1 : 0
    }

    const {
        tableData, isLoading, tableParams, handleTableChange
    } = useTable({
        search: search,
        request: table.request,
        hasPagination: true,
        // body: bodyRequest
    })


    return (

        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Row justify={'space-between'} gutter={[16, 16]}>
                    <Col flex={`${getFlexGrowByGrid()} 1 400px`}>
                        <Search onSearch={(value) => setSearch(value)}/>
                    </Col>
                    <Col flex={`${getFlexGrowByGrid()} 1 200px`}>
                        {createButton && <Button icon={<PlusOutlined/>} block {...createButton}>
                            Adicionar
                        </Button>}
                    </Col>
                </Row>
            </Col>


            <Col xs={24}>
                <Table columns={table.columns} onChange={handleTableChange}
                       tableParams={tableParams}
                       loading={isLoading}
                       noScroll
                       onRowClick={table.onRowClick}
                    // showSizeChanger={false}
                    //    onRowClick={handleRowClick}
                       dataSource={tableData}/>
            </Col>
        </Row>

    );
}
