import React from 'react';
import {Empty as EmptyAnt} from "antd";


export default function Empty(props) {
    return (
        <EmptyAnt
            description={'Não existe dados no momento'}

            {...props}/>
    )
}