import React, {useEffect, useMemo, useState} from 'react';
import {Form, Space} from "antd";
import FormBuild from "./FormBuild";
import Map from '../../data_display/Map';
import useDefaultGetRequest from "../../../hooks/useDefaultGetRequest";
import {API_PATHS} from "../../../constans/ApiPaths";
import {generateSelectOptions} from "../../../utils/Utils";
import FormItem from "./FormItem";

const FIELDS_NAMES = {
    ADDRESS: 'address',
    LATITUDE: 'latitude',
    LONGITUDE: 'longitude',
    LIMIT_AREA: 'limit_area',
}

function LocationMap({
                         children,
                         value: values,
                         onChange,
                         ...props
                     }) {
    const {status} = Form.Item.useStatus();
    useEffect(() => {
        if (status === 'error') { //verificar o estado do form.item pai para validar os filhos e exibiro error
            internalForm.validateFields()
        }
    }, [status])
    const [internalForm] = Form.useForm()

    const addressValue = Form.useWatch(FIELDS_NAMES.ADDRESS, internalForm)
    const latitudeValue = Form.useWatch(FIELDS_NAMES.LATITUDE, internalForm)
    const longitudeValue = Form.useWatch(FIELDS_NAMES.LONGITUDE, internalForm)
    const limitAreaValue = Form.useWatch(FIELDS_NAMES.LIMIT_AREA, internalForm)

    const [currentPosition, setCurrentPosition] = useState()

    const {
        data: adresses,
        isLoading: isLoadingAddress
    } = useDefaultGetRequest({
        url: API_PATHS.GET_ADDRESS,
        params: {
            search: addressValue?.address,
            lat: latitudeValue,
            lng: longitudeValue,
        },
        dependence: [addressValue?.address, latitudeValue, longitudeValue]
    })


    useEffect(() => {
        if (latitudeValue && longitudeValue) {

            setCurrentPosition({lat: latitudeValue, lng: longitudeValue})
        }
    }, [latitudeValue, longitudeValue])


    const items = useMemo(() => [
        {
            field_type: 'AutoComplete',
            // value: value?.[FIELDS_NAMES.ADDRESS],
            col: {xs: 24},
            options: generateSelectOptions(adresses, 'address', 'address'),
            // onSelect: (v) => console.log(v),
            form: {
                label: 'Endereço(falta a estrategias do id)',
                name: FIELDS_NAMES.ADDRESS,
                rules: [{
                    required: true,
                    message: 'Endereço é obrigatório'
                }],
            }
        },

        {
            field_type: 'Input',
            // value: value?.[FIELDS_NAMES.LATITUDE],
            type: 'number',
            col: {lg: 8, md: 12, xs: 24},
            form: {
                label: 'Latitude',
                name: FIELDS_NAMES.LATITUDE,

                rules: [{
                    required: true,
                    message: 'Latitude é obrigatório'
                },]
            }
        },
        {
            field_type: 'Input',
            // value: value?.[FIELDS_NAMES.LONGITUDE],
            type: 'number',
            col: {lg: 8, md: 12, xs: 24},
            form: {
                label: 'Longitude',
                name: FIELDS_NAMES.LONGITUDE,
                rules: [{
                    required: true,
                    message: 'Longitude é obrigatório'
                }]
            }
        }, {
            field_type: 'Input',
            min: 0,
            type: 'number',
            // value: value?.[FIELDS_NAMES.LIMIT_AREA],
            col: {lg: 8, xs: 24},
            form: {
                label: 'Área limite para batida de ponto em metros',
                name: FIELDS_NAMES.LIMIT_AREA,
                rules: [{
                    required: true,
                    message: 'Área limite é obrigatório'
                }]

            }
        },

    ], [adresses])

    const [formItems, setFormItems] = useState(items)

    useEffect(() => {
        setFormItems(items)
    }, [adresses])


    return (
        <Space direction={'vertical'} style={{width: '100%'}}>
            <FormBuild form={internalForm} fields={formItems}
                    initialValues={values}
                       onValuesChange={(value, values) => {
                           if (values[FIELDS_NAMES.ADDRESS] && values[FIELDS_NAMES.LATITUDE] && values[FIELDS_NAMES.LONGITUDE] && values[FIELDS_NAMES.LIMIT_AREA]) {
                               onChange?.(values)
                           } else {
                               onChange?.(null)
                           }
                       }}
            />
            <Map position={currentPosition} limitArea={limitAreaValue}

                 onChange={(position) => {
                     internalForm.setFieldValue(FIELDS_NAMES.LATITUDE, position.lat)
                     internalForm.setFieldValue(FIELDS_NAMES.LONGITUDE, position.lng)
                 }}/>
        </Space>
    )
}

export default function LocationMapFormField({form, ...props}) {
    return (
        <FormItem {...form}>
            <LocationMap {...props}/>
        </FormItem>
    )
}