import React, {useMemo} from 'react';
import {Tag, Typography} from "antd";
import {useNavigate} from "react-router-dom";
import {
    formatPath,
    generateFieldsByColumns
} from "../../utils/Utils";
import DefaultListPage from "../../components/other/DefaultListPage";
import {API_PATHS} from "../../constans/ApiPaths";
import {PATHS} from "../../constans/Paths";
import {USER_PERMISSIONS_CHOICES} from "../../constans/choices";


export default function PageListUser() {
    const navigate = useNavigate()
    const columns = [
        {
            title: 'Nome',
            dataIndex: 'get_full_name',
            sorter: 'get_full_name',
            key: 'get_full_name',
        },
        {
            title: 'Nome de usuário',
            dataIndex: 'username',
            sorter: 'username',
            key: 'username',
        },
        {
            title: 'Permissões',
            dataIndex: 'permission',
            key: 'permission',
            render: (values, object) => {
                if (!values) return '-'
                return (
                    values?.map((value) =>
                        <Tag>{USER_PERMISSIONS_CHOICES.find(({id}) => id === value).value}</Tag>)
                )
            }
        },

    ]


    const fields = useMemo(() => {
        return `${generateFieldsByColumns(columns)},id`
    }, [])

    const table = {
        columns,
        request: {
            url: API_PATHS.USERS,
            extraParams: {
                fields: fields
            }
        },
        onRowClick: (values) => {
            navigate(formatPath(PATHS.UPDATE_USER, {
                id: values.id,
            }))
        }
    }

    return (
        <>
            <Typography.Title>Usuários</Typography.Title>
            <DefaultListPage columns={columns}
                             table={table}
                             createButton={
                                 {onClick: () => navigate(PATHS.CREATE_USER)}}
            />
        </>
    );
}