import {useEffect, useState} from "react";
import api from "../services/api";
import {BuildFieldErrorsDict} from "../utils/Utils";
import {PATHS} from "../constans/Paths";
import {useNavigate} from "react-router-dom";


export default function useDefaultGetRequest({
                                                 url,
                                                 params,
                                                 redirect = false,
                                                 makeRequest = true,
                                                 hasPagination = false,
                                                 dataDefault = '',
                                                 dependence = []
                                             }) {

    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState(dataDefault)
    const [response, setRespone] = useState()
    const navigate = useNavigate()
    useEffect(() => {
        if (makeRequest) fetchData()
    }, [...dependence, makeRequest])

    const fetchData = async () => {
        try {
            setIsLoading(true)
            const response = await api.get(url, {params: params});

            if (hasPagination) {
                setData(response.data.results)

            } else {
                setData(response.data)
            }
            setRespone(response)
        } catch (error) {
            if (redirect && error.response.status === 404) {

                navigate(PATHS.ROOT)
            }
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false);
        }
    }

    return {
        response,
        data,
        isLoading
    }
}