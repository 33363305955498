import React from "react";
import styled from 'styled-components';
import {Layout, Typography} from "antd";
import {red} from "../../../utils/Colors";
import ClientSelector from "./ClientSelector";
import UserDropdown from "./UserDropdown";
import CollapsedMenuButton from "../CollapsedMenuButton";
import useLayoutContent from "../../../context/LayoutContext";
import {useUserContext} from "../../../context/UserContext";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const {Header: HeaderAnt} = Layout;

const Header = styled(HeaderAnt)`
    height: var(--header-height);
    padding: 0px 16px;
    background: ${red};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

    & > * {
        width: fit-content;
    }
`
const MAX_CARACTERES = 15;

export default function ActionBar(props) {
    const {floatMenu} = useLayoutContent();
    const {client} = useUserContext();
        const {sm} = useBreakpoint();

    function handleShowName() {
        const title = client?.title

        if (sm) {
            return title?.toUpperCase()
        } else if (title?.length > MAX_CARACTERES) {
            return `${title?.toUpperCase().substring(0, MAX_CARACTERES)}...`
        }
        return title?.toUpperCase()

    }

    return (

        <Header>
            {floatMenu && <CollapsedMenuButton style={{marginRight: 'auto'}}/>}
            <ClientSelector/>
            <Typography.Title level={5} style={{color: 'white', marginBottom:0}}>{handleShowName()}</Typography.Title>
            <Typography.Text type={'secondary'}>
                v.1.0
            </Typography.Text>
            <UserDropdown/>
        </Header>

    );
}
