import {createGlobalStyle} from 'styled-components';


const GlobalStyle = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    //font-size: 1rem;
    //transition: 0.3s;
    color: inherit;
  }
  
  :root{
    --header-height: 56px;
  }

  html {
    scrollbar-width: thin;
    //background: #e8e8e8;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5 h6 {
    font-weight: normal;
  }

  img {
    display: block;
    max-width: 100%;

  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto' !important;
    //font-family: "Fira Code Retina" !important;

  }
  
  .ant-form-item{
    margin-bottom: 16px;
  }

`;

export default GlobalStyle;