import {ToastNotification} from "../components/feedback/ToastNotification";
import {DEBUG} from "../settings";
import dayjs from 'dayjs';

export function maskIntNumber(value) {

    if (!value) {
        if (value !== 0) return '-'
    }
    return maskNumber.format(value)
}

export const maskNumber = Intl.NumberFormat('pt-BR')

export const BuildFieldErrorsDict = (error, setValidatedFields, toastMessage = false) => {
    DEBUG && console.error(error)
    toastMessage && ToastNotification(toastMessage, 'error')
    // debugger
    if (error?.response) {
        DEBUG && console.error(error.response)
        const {
            errors: errorsBack,
            code: errorCode
        } = error.response.data;
        if (errorCode === 'field_error') {

            let errors = []

            Object.entries(errorsBack).forEach((value) => {
                let erro = value[1]
                if (!(value[1] instanceof Array)) erro = [value[1]]
                errors.push({name: value[0], errors: erro})

            })
            ToastNotification('Error em alguns campos', 'error')
            setValidatedFields && setValidatedFields(errors)
        } else if (error.response.data) {
            let response_errors = error.response.data.errors || error.response.data.detail || error.response.data.non_field_errors || error.response.data.error

            if (response_errors) {
                if (Array.isArray(response_errors)) {
                    ToastNotification(response_errors[0], 'error')
                } else {
                    ToastNotification(response_errors, 'error')
                }
            }
        }
    }

}


export function DateFormat(date, format = 'DD/MM/YYYY') {
    if (date) return dayjs(date).format(format)
}

export function ParseDate(date) {
    if (date) return dayjs(date)
}


export function remove_empty_values(form_data) {
    let tags_to_remove = []
    for (var pair of form_data.entries()) {
        if (!pair[1]) {
            tags_to_remove.push(pair[0]);
        }
    }
    tags_to_remove.forEach((tag) => {
        form_data.delete(tag)
    })
    return form_data
}

export function convertByteToMegaByte(byte) {
    return byte / 1048576
}

export function getAllFilters(formData, object) {
    const allFilterData = formData || new FormData();
    for (const key in object) {
        if (Array.isArray(object[key])) {
            for (const value of object[key]) {
                allFilterData.append(key, value);
            }
            continue;
        }
        allFilterData.append(key, object[key]);
    }
    return allFilterData;
}

export function convertBooleanValues(obj) {
    const convertedObj = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (value === 'true') {
                convertedObj[key] = true;
            } else if (value === 'false') {
                convertedObj[key] = false;
            } else {
                convertedObj[key] = value;
            }
        }
    }
    return convertedObj;
}

export function any(iterable) {
    for (let index = 0; index < iterable.length; index++) {
        if (iterable[index]) return true;
    }
    return false;
}

export function all(iterable) {
    for (let index = 0; index < iterable.length; index++) {
        if (!iterable[index]) return false;
    }
    return true;
}

export function redirect_by_path() {
    let path = '/'
    try {
        let splited = window.location.pathname.split('/')
        if (splited[0].length > 0) {
            path += splited[0]
        } else {
            path += splited[1]
        }
    } catch (e) {
        ;
    }
    return path
}

export function redirect_tag_a(link) {
    const tag_link = window.document.createElement('a')
    tag_link.href = link
    tag_link.click()
}

export function push_by_path(history) {
    let path = redirect_by_path()
    history.push(path)
}


export function getFileExtensionByPath(path) {
    if (!path) return ''
    return path.match(/\.[a-zA-Z]+\?/)[0].replace(/\?/, '')
}

export function groupByN(arr, n) {
    const groupedArray = [];
    for (let i = 0; i < arr.length; i += n) {
        groupedArray.push(arr.slice(i, i + n));
    }
    return groupedArray;
}


export function CPFMask(value) {
    let newValue = value.replaceAll(/[^\d]/g, '')
    if (newValue) return `${newValue.substring(0, 3)}.${newValue.substring(3, 6)}.${newValue.substring(6, 9)}-${newValue.substring(9, 11)}`
    return ''

}

function addNumberPointsBySteps(value, steps = 3) {
    let finalNumber = ''
    let numberSteps = steps * -1
    while (true) {
        let lastNumber = value.slice(numberSteps)
        if (lastNumber) {
            if (finalNumber) {
                finalNumber = `${lastNumber}.${finalNumber}`
            } else {
                finalNumber = lastNumber
            }
            let indexToSlice
            if (value.length < steps) {
                indexToSlice = numberSteps
            } else {
                indexToSlice = (value.length - steps)
            }
            value = value.slice(0, indexToSlice)
        } else {
            break;
        }
    }
    return finalNumber
}

export function cfpMask(value) {
    let number = value.toString().replace(/([^\d])/g, '');//tira caracteres e zero no inicio

    if (number === '') {
        return ``
    }
    const limit = 11
    if (number.length > limit) {
        number = number.substring(0, 11)
    }
    if (number.length > 2) {
        number = `${addNumberPointsBySteps(number.slice(0, (number.length - 2)))}-${number.slice(-2)}`//x.xxx,xx ...
    }

    return number
}

export function generateFieldsByColumns(columns) {
    return columns.map(({key}) => key).join(',')
}


export function generateSelectOptions(values, key_label, key_value = 'id') {
    if (values instanceof Array) {
        return values.map((value) => {
            return {
                label: value[key_label],
                value: value[key_value],
            }
        })
    }
    return []
}

export function formatPath(path, parms, queryParams) {

    let newPath = path
    if (parms) {
        Object.keys(parms).forEach((key) => {
            newPath = newPath.replace(`:${key}`, parms[key])
        })
    }
    if (queryParams) {
        newPath += '?'
        Object.keys(queryParams).forEach((key, index) => {
            let caractere = '&'
            if (index === 0) caractere = ''
            newPath += `${caractere}${key}=${queryParams[key]}`
        })
    }

    return newPath
}

export function valueOrEmptyCaractere(value) {
    return value ? value : '-'
}