import React, {useState} from 'react';
import {Modal, Form as FormAnt, Row, Col} from "antd";
import api from "../../../services/api";
import {API_PATHS} from "../../../constans/ApiPaths";
import {
    ToastNotification
} from "../../../components/feedback/ToastNotification";
import {
    BuildFieldErrorsDict, formatPath,
} from "../../../utils/Utils";
import FormBuild, {
    FIELDS_NAMES
} from "../../../components/data_entry/form_field/FormBuild";
import PasswordCheckList from "../../../components/other/PasswordCheckList";
import {useParams} from "react-router-dom";


const FORM_NAMES = {
    PASSWORD: 'password',
    CONFIRM_PASSWORD: 'confirm_password',
    VALIDATION_PASSOWRD: 'validation_password'

}

export default function ResetUserPasswordModal({open, setOpen}) {
    const [form] = FormAnt.useForm()
    const [isLoading, setIsLoading] = useState()
     const passwordValue = FormAnt.useWatch(FORM_NAMES.PASSWORD, form) || ''
    const confirmPasswordValue = FormAnt.useWatch(FORM_NAMES.CONFIRM_PASSWORD, form) || ''
    const {id} = useParams()

    async function handleSubmit() {
        try {
            setIsLoading(true)
            let data = await form.getFieldsValue()
            delete data['validation_password']
            const response = await api.post(formatPath(API_PATHS.USER_RESET_PASSWORD, {id}), data);
            ToastNotification('Convites enviador com sucesso')
            handleClose()

        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, false)
        } finally {
            setIsLoading(false);
        }
    }

    function handleClose() {
        setOpen(false)
        form.resetFields()
    }

    const formItems = [

        {
            field_type: FIELDS_NAMES.PASSWORD_INPUT,
            col: {xs: 24},
            form: {
                label: 'Senha',
                name: FORM_NAMES.PASSWORD,

                rules: [{
                    required: true,
                    message: 'Senha é obrigatório'
                }]
            },
        },
        {
            field_type: FIELDS_NAMES.PASSWORD_INPUT,
            col: {xs: 24},
            form: {
                label: 'Confirmar senha',
                name: FORM_NAMES.CONFIRM_PASSWORD,

                rules: [{
                    required: true,
                    message: 'Confirmar senha é obrigatório'
                }]
            },
        },

        {
            field_type: FIELDS_NAMES.CUSTOM_FIELD,
            col: {xs: 24},
            form: {
                name: FORM_NAMES.VALIDATION_PASSOWRD,
                rules: [
                    {
                        validator: async (rule, value) => {

                            if (!value) {

                                return Promise.reject(new Error('A senha e o confirmar senha precisa seguir os padrões acima.'))
                            }
                        },
                    }
                ]
            },
            render: <PasswordCheckList password={passwordValue}
                                       confirmPassword={confirmPasswordValue}/>,
        },

    ]


    return (

        <Modal
            open={open}
            title="Redefinir senha"
            okButtonProps={{
                loading: isLoading
            }}
            okText={'Redefinir senha'}
            onOk={() => {
                form.submit()
            }}
            onCancel={handleClose}

        >
            <FormBuild
                onFinish={handleSubmit}
                fields={formItems} form={form}
               />
        </Modal>

    );
}
