import React, {useMemo} from 'react';
import {Badge, Tag, Typography} from "antd";
import {PATHS, TAB_POLL_PATH} from "../../constans/Paths";
import {useNavigate} from "react-router-dom";
import {
    CPFMask,
    DateFormat,
    formatPath,
    generateFieldsByColumns, valueOrEmptyCaractere
} from "../../utils/Utils";
import DefaultListPage from "../../components/other/DefaultListPage";
import {API_PATHS} from "../../constans/ApiPaths";
import PollTag from "../../components/other/PollTag";


export default function PageListPoll() {
    const navigate = useNavigate()
    const columns = [
        {
            title: 'Titulo',
            dataIndex: 'title',
            // sorter: 'title',
            key: 'title',
        }, {
            title: 'Status',
            dataIndex: 'status',
            // sorter: 'start_date',
            key: 'status',
            render: (value, object) => {
                return <PollTag value={value}/>
            }
        },
        //  {
        //     title: 'Data de inicio',
        //     dataIndex: 'start_date',
        //     sorter: 'start_date',
        //     key: 'start_date',
        //      render: (date) => DateFormat(date)
        // },
        {
            title: 'Número de entrevistas',
            dataIndex: 'numbers_of_interviews',
            // sorter: 'numbers_of_interviews',
            key: 'numbers_of_interviews',
        }

    ]


    const fields = useMemo(() => {
        return `${generateFieldsByColumns(columns)},id`
    }, [])

    const table = {
        columns,
        request: {
            url: API_PATHS.POLL,
            extraParams: {
                fields: fields
            }
        },
        onRowClick: (values) => {
            navigate(formatPath(PATHS.UPDATE_POLL, {
                id: values.id,
                tab_id: TAB_POLL_PATH.DETAIL
            }))
        }
    }

    return (
        <>
            <Typography.Title>Pesquisas</Typography.Title>
            <DefaultListPage columns={columns}
                             table={table}
                             createButton={
                                 {onClick: () => navigate(PATHS.CREATE_POLL)}}/>
        </>
    );
}
