import React from 'react';
import FormItem from "./FormItem";
import InputSelectTableField from "../InputSelectTableField";

export default function InputSelectTableFormField({form, ...props}) {
    return (
        <FormItem {...form}>

            < InputSelectTableField
                title={form.label}
                {...props}
            />
        </FormItem>
    )
}