import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components'
import {
    Button, Col, Form as FormAnt, Row, Typography
} from "antd";
import {NavLink, useNavigate} from "react-router-dom";
import {BuildFieldErrorsDict} from "../../utils/Utils";
import api from "../../services/api";
import {
    isAuthenticated,
    saveTokens
} from "../../utils/Auth";
import {UserOutlined, LockOutlined} from "@ant-design/icons";
import Form from "../../components/data_entry/form_field/Form";
import LayoutLogin from "../../layout/authentication/LayoutLogin";
import InputFormField from "../../components/data_entry/form_field/InputFormField";
import PasswordFormField from "../../components/data_entry/form_field/PasswordFormField";
import {ToastNotification} from "../../components/feedback/ToastNotification";
import {PATHS} from "../../constans/Paths";
import {useUserContext} from "../../context/UserContext";
import useQuery from "../../hooks/useQuery";


const FORM_NAMES = {
    USER: 'username',
    PASSWORD: 'password',
}

export default function Login() {
    const [form] = FormAnt.useForm()
    const [isLoading, setIsLoading] = useState(false)//todo fazer um hook
    const {refreshUser} = useUserContext()
    const navigate = useNavigate();
    const querys = useQuery();
    const loginCounter = useRef(0)

    useEffect(() => {
        // form.setFieldsValue({
        //     [FORM_NAMES.USER]:'admin@gmail.com',
        //    [FORM_NAMES.PASSWORD]:'admin',
        // })

        if (isAuthenticated()) {
            navigate('/')
        }
    }, []);

    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            const data = await form.getFieldsValue()
            const response = await api.post('authentication/login/', data);
            saveTokens({
                'access': response.data.access,
                'refresh': response.data.refresh
            });
            refreshUser()
            ToastNotification('Login realizado com sucesso!')
            const next = querys.get('next')
            querys.delete('next')
            let options = {
                pathname: next ? next : PATHS.POLL,
                search: next ? querys.toString() : ''
            }
            navigate(options)

        } catch (error) {
            ++loginCounter.current
            BuildFieldErrorsDict(error, form.setFields, loginCounter.current > 2 ? 'Caso tenha esquecido sua senha entre em contato com o suporte': false)
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <>
            <LayoutLogin>
                <Form form={form}
                      requiredMark={false}
                      onFinish={handleSubmit}
                >
                    <Row>
                        <Col xs={24}>
                            <InputFormField prefix={<UserOutlined/>} form={{
                                label: 'Usuário',
                                name: FORM_NAMES.USER,

                                rules: [{
                                    required: true,
                                    message: 'Usuário é obrigatório'
                                }]
                            }} placeholder={'Digite seu email aqui...'}/>
                        </Col>

                        <Col xs={24}>
                            <PasswordFormField
                                type={'password'}
                                prefix={<LockOutlined/>}
                                form={{
                                    label: 'Senha',
                                    name: FORM_NAMES.PASSWORD,
                                    rules: [{
                                        required: true,
                                        message: 'Senha é obrigatório'
                                    },
                                    ]
                                }}
                                placeholder={'Digite sua senha aqui...'}/>

                        </Col>
                        <Col xs={24}>
                            <FormAnt.Item style={{marginBottom: 8}}>
                                <Button block type={'primary'}
                                        htmlType="submit"
                                        loading={isLoading}>Entrar</Button>
                            </FormAnt.Item>

                        </Col>
                        {/*<Col xs={24}>*/}
                        {/*    <NavLink*/}
                        {/*        to={PATHS.FORGOT_PASSWORD}>Esqueceu sua*/}
                        {/*        senha?</NavLink>*/}
                        {/*</Col>*/}
                        {/*<Col xs={24}>*/}
                        {/*    <NavLink*/}
                        {/*        to={PATHS.REGISTER}>Cadastrar novo*/}
                        {/*        usuário</NavLink>*/}
                        {/*</Col>*/}
                    </Row>

                </Form>
            </LayoutLogin>
        </>

    );
}
