const POLL_DETAIL = 'polls/:id/'

export const API_PATHS = {
    AUTH_USER: `authentication/me/`,
    RECOVER_PASSWORD: `authentication/send_email_recover_password/`,


    INTERVIEWERS_POLL_POST: 'interviewer_poll/filter_list/',
    INTERVIEWER: `interviewer_poll/`,
    INTERVIEWER_DETAIL: `interviewer_poll/:id/`,

    // INTERVIEWS: `interviews/`,
    INTERVIEWS_DETAIL: `interviews/:id/`,

    POLL: 'polls/',
    POLL_DETAIL: POLL_DETAIL,
    POLL_QUESTIONS: `${POLL_DETAIL}questions/`,
    POLL_INTERVIEWERS: `${POLL_DETAIL}interviewers/`,
    POLL_RESULTS: `${POLL_DETAIL}results/`,
    POLL_INTERVIEWS: `${POLL_DETAIL}interviews/`,
    POLL_INTERVIEWS_LOCATIONS: `${POLL_DETAIL}interviews_locations/`,
    POLL_QUESTION_RESPONSE: `${POLL_DETAIL}normalize_question_response/`,
    POLL_PRINT_TABLE: `${POLL_DETAIL}print_table/`,
    POLL_PRINT_INTERVIEWS: `${POLL_DETAIL}print_interviews/`,


    COMPANYS: 'company/',
    COMPANY_DETAIL: 'company/:id/',

    ROLES: 'role/',
    ROLE_DETAIL: '/role/:id/',


    SECRETARYS: 'secretary/',
    SECRETARY_DETAIL: '/secretary/:id/',

    SECTORS: 'sector/',
    SECTOR_DETAIL: '/sector/:id/',

    BONDS: 'bond/',
    BOND_DETAIL: '/bond/:id/',

    //holiday crud
    HOLIDAY: 'holiday/',
    HOLIDAY_DETAIL: '/holiday/:id/',

    //holiday crud
    DEVICES: 'device/',
    DEVICE_DETAIL: '/device/:id/',

    WORK_UNITS: 'work_unit/',
    WORK_UNIT_DETAIL: 'work_unit/:id/',

//     BONDS: 'bond/',
//     BOND_DETAIL: '/bond/:id/',
//
//
//     //work_unit crud
//     WORK_UNIT: '/work_unit',
//     UPDATE_WORK_UNIT: '/work_unit/:id/',
//     CREATE_WORK_UNIT: '/work_unit/create',
//
    //justification crud
    JUSTIFICATION: '/justification/',
    JUSTIFICATION_DETAIL: '/justification/:id/',

    //scale crud
    SCALE_JOURNEY: '/scale_journey/',
    SCALE_JOURNEY_DETAIL: '/scale_journey/:id/',


//
//     //device crud
//     DEVICE: '/device',
//     UPDATE_DEVICE: '/device/:id/',
//     CREATE_DEVICE: '/device/create',
//
    //workday crud
    WORKDAYS: '/workday/',
    WORKDAY_DETAIL: '/workday/:id/',

    //USERS crud
    USERS_POST: '/user/filter_list/',

    USERS: '/user/',
    USER_DETAIL: '/user/:id/',
    USER_RESET_PASSWORD: `user/:id/reset_password/`,

    //journey crud
    JOURNEY: '/journey/',
    JOURNEY_DETAIL: '/journey/:id/',

    //journey crud
    CONFIG_MARKUP: '/config_markup/',

    GET_ADDRESS: '/get_address/',

    //journey crud
    EMPLOYEE: '/employee/',
    EMPLOYEE_DETAIL: '/employee/:id/',


}

