import React, {useEffect, useState} from 'react';
import {Card, Col, Popconfirm, Row, Form as FormAnt} from "antd";

import styled from 'styled-components'
import {UnorderedListOutlined, FormOutlined} from '@ant-design/icons';
import DroppableForm from "./components/DroppableForm";
import useDefaultGetRequest from "../../../hooks/useDefaultGetRequest";
import {BuildFieldErrorsDict, formatPath} from "../../../utils/Utils";
import {API_PATHS} from "../../../constans/ApiPaths";
import Loading from "../../../components/feedback/Loading";
import Button from "../../../components/data_entry/Button";
import api from "../../../services/api";
import {
    ToastNotification
} from "../../../components/feedback/ToastNotification";
import Form from "../../../components/data_entry/form_field/Form";

const OptionQuestion = styled.div`
    font-size: 18px;
    font-weight: bold;
    display: flex;
    gap: 8px;
    border: 1px solid rgb(181 176 176 / 45%);
    padding: 8px 16px;
    border-radius: 4px;
    cursor: grab;
    margin-bottom: 16px;

`

const StyledOptionContainer = styled(Card)`
    position: sticky;
    top: 0;

`


const OPTIONS_QUESTIONS = [
    {
        name: 'Espontânea',
        icon: <FormOutlined/>,
        type: 'Espontanea'
    },
    {
        name: 'Induzida',
        icon: <UnorderedListOutlined/>,
        type: 'Induzida'
    }
]

export default function TabFormBuildPoll({poll}) {
    const [questions, setQuestions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [form] = FormAnt.useForm()

    const {
        data: _questions,
        isLoading: isLoadingQuestion
    } = useDefaultGetRequest({
        url: formatPath(API_PATHS.POLL_QUESTIONS, {id: poll.id}),
        makeRequest: !!poll.id
    })
    useEffect(() => {
        if (_questions) {
            setQuestions(_questions)
        }
    }, [_questions])

    const [startDragQuestion, setStartDragQuestion] = useState(false)

    async function handleSubmit() {
        try {
            setIsLoading(true)

            form.validateFields()
                .then(async (values) => {
                    const response = await api.post(`polls/${poll.id}/save_form/`, questions);
                    setQuestions(response.data.object)
                    window.location.reload();
                    ToastNotification(response.data.detail)
                    // navigate(PATHS.LOGIN)
                })
                .catch((errorInfo) => {
                    ToastNotification('Alguns campos são obrigátorios.', 'warning')
                });


        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, false)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Row gutter={[16, 16]}>
            {/*<Col xs={24}><div ref={testeRef}>teste</div></Col>*/}

            <Col xs={{span: 24, order: 2}} lg={{span: 16, order: 1}}>
                <Form form={form} disabled={poll?.started}>
                    {isLoadingQuestion ? <Loading/> :
                        <DroppableForm poll={poll} questions={questions}
                                       setQuestions={setQuestions}
                                       setStartDragQuestion={setStartDragQuestion}
                                       startDragQuestion={startDragQuestion}/>}
                </Form>
            </Col>
            <Col xs={{span: 24, order: 1}} lg={{span: 8, order: 2}}>
                <StyledOptionContainer title="Opções">
                    {OPTIONS_QUESTIONS.map(({name, icon, type}) => (
                        <OptionQuestion
                            setStartDragQuestion={setStartDragQuestion}

                            draggable={!poll.started} data-type={type}
                            onDragStart={(e) => {
                                e.dataTransfer.setData('type', type)
                                // e.dataTransfer.setDragImage(testeRef.current, 0,0)
                                setStartDragQuestion(true)
                            }}
                            onDragEnd={() => setStartDragQuestion(false)}
                        >{icon}{name}</OptionQuestion>
                    ))}

                </StyledOptionContainer>
            </Col>
            <Col xs={{span: 24, order: 3}}> <Row justify={'end'}
                                                 gutter={[8, 8]}>

                <Col>
                    <Popconfirm
                        title={`Salvar pesquisa`}
                        description="Tem certeza que deseja realizar está ação?"
                        onConfirm={handleSubmit}
                        okText="Sim"
                        okButtonProps={{loading: isLoading}}
                        cancelText="Cancelar"
                        disabled={poll.started}
                    >
                        <Button disabled={poll.started} loading={isLoading}>Salvar
                            formulário </Button>
                    </Popconfirm>

                </Col>
            </Row></Col>
        </Row>
    );
}
