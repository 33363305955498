import React  from 'react';
import {Col, Divider, Popconfirm, Row, Typography} from "antd";
import {PATHS, TAB_POLL_PATH} from "../../constans/Paths";
import useCreateBreadcrumbs from "../../hooks/useCreateBreadcrumbs";
import Button from "../../components/data_entry/Button";
import FormBuild from "../../components/data_entry/form_field/FormBuild";
import {useNavigate} from "react-router-dom";
import useUserForm from "./hooks/useUserForm";


const breadcrumbs = [
    {
        title: 'Usuários',
        link: PATHS.USER
    },
    {
        title: 'Criar novo usuário',
    },

]


export default function PageCreateUser() {
    useCreateBreadcrumbs(breadcrumbs)

    const {
        isLoading,
        formItems,
        handleSubmit,
        form,
        formIsLoading
    } = useUserForm()


    const navigate = useNavigate()





    return (
        <>
            <Typography.Title>Criar novo usuário</Typography.Title>
            <Divider/>
            <FormBuild loading={isLoading} onFinish={handleSubmit}
                // onValuesChange={handleFields}
                       fields={formItems} form={form}
                       footer={
                           <Row justify={'end'} gutter={[8, 8]}>
                               <Col>
                                     <Popconfirm
                                       title={`Cancelar cadastro`}
                                       description="Tem certeza que deseja realizar está ação?"
                                       onConfirm={() => navigate(PATHS.USER)}
                                       okText="Sim"
                                       okButtonProps={{loading: formIsLoading}}
                                       cancelText="Cancelar"
                                   >
                                       <Button loading={formIsLoading}
                                           type={"default"}>Cancelar</Button>
                                   </Popconfirm>

                               </Col>
                               <Col>
                                   <Button loading={formIsLoading}
                                           htmlType="submit">Salvar</Button>
                               </Col>
                           </Row>
                       }/>

        </>
    );
}
