import React, {useMemo, useState} from 'react';
import {Form as FormAnt, Modal} from "antd";
import {
    FIELDS_NAMES
} from "../../../components/data_entry/form_field/FormBuild";
import PasswordCheckList from "../../../components/other/PasswordCheckList";
import {
    BuildFieldErrorsDict, formatPath,
    generateSelectOptions
} from "../../../utils/Utils";
import {
    CONFIG_MARKUP_TYPE_IDENTIFY,
    USER_PERMISSIONS_CHOICES
} from "../../../constans/choices";
import api from "../../../services/api";
import {API_PATHS} from "../../../constans/ApiPaths";
import {
    ToastNotification
} from "../../../components/feedback/ToastNotification";
import {PATHS} from "../../../constans/Paths";
import {useNavigate, useParams} from "react-router-dom";
import useDefaultGetRequest from "../../../hooks/useDefaultGetRequest";
import {ExclamationCircleFilled} from "@ant-design/icons";

const FORM_NAMES = {
    USERNAME: 'username',
    FIRST_NAME: 'first_name',
    LAST_NAME: 'last_name',
    PASSWORD: 'password',
    CONFIRM_PASSWORD: 'confirm_password',
    PERMISSIONS: 'permission',
    VALIDATION_PASSOWRD: 'validation_password'

}

export default function useUserForm() {


    const [form] = FormAnt.useForm();
    const [formIsLoading, setFormIsLoading] = useState();

    const passwordValue = FormAnt.useWatch(FORM_NAMES.PASSWORD, form) || ''
    const confirmPasswordValue = FormAnt.useWatch(FORM_NAMES.CONFIRM_PASSWORD, form) || ''

    const {id} = useParams()
    const {
        data: user,
        isLoading: isLoadingUser
    } = useDefaultGetRequest({
        url: formatPath(API_PATHS.USER_DETAIL, {id: id}),
        makeRequest: !!id
    })

    const navigate = useNavigate()

    const formItems = [
        {
            field_type: FIELDS_NAMES.INPUT,
            col: {md: 12, xs: 24},
            form: {
                label: 'Nome',
                name: FORM_NAMES.FIRST_NAME,

                rules: [{
                    required: true,
                    message: 'Nome é obrigatório'
                }]
            },
        },
        {
            field_type: FIELDS_NAMES.INPUT,
            col: {md: 12, xs: 24},
            form: {
                label: 'Sobrenome',
                name: FORM_NAMES.LAST_NAME,

                rules: [{
                    required: true,
                    message: 'Sobrenome é obrigatório'
                }]
            },
        },
        {
            field_type: FIELDS_NAMES.INPUT,
            col: {md: 12, xs: 24},
            disabled: !!id,
            form: {
                label: 'Nome de usuário (OBS: será usado para fazer o login no sistema)',
                name: FORM_NAMES.USERNAME,

                rules: [{
                    required: true,
                    message: 'Nom de usuário é obrigatório'
                }]
            },
        },
        {
            field_type: FIELDS_NAMES.SELECT,
            col: {md: 12, xs: 24},
            mode: "multiple",
            form: {
                label: 'Permissões',
                name: FORM_NAMES.PERMISSIONS,
                rules: [{
                    required: true,
                    message: 'Permissões é obrigatório'
                }]
            },
            options: generateSelectOptions(USER_PERMISSIONS_CHOICES, 'value')
        },
        {
            field_type: FIELDS_NAMES.PASSWORD_INPUT,
            col: {md: 12, xs: 24},
            form: {
                label: 'Senha',
                name: FORM_NAMES.PASSWORD,

                rules: [{
                    required: true,
                    message: 'Senha é obrigatório'
                }]
            },
            show: !!!id
        },
        {
            field_type: FIELDS_NAMES.PASSWORD_INPUT,
            col: {md: 12, xs: 24},
            form: {
                label: 'Confirmar senha',
                name: FORM_NAMES.CONFIRM_PASSWORD,

                rules: [{
                    required: true,
                    message: 'Confirmar senha é obrigatório'
                }]
            },
            show: !!!id
        },

        {
            field_type: FIELDS_NAMES.CUSTOM_FIELD,
            col: {md: 12, xs: 24},
            form: {
                name: FORM_NAMES.VALIDATION_PASSOWRD,
                rules: [
                    {
                        validator: async (rule, value) => {

                            if (!value) {

                                return Promise.reject(new Error('A senha e o confirmar senha precisa seguir os padrões acima.'))
                            }
                        },
                    }
                ]
            },
            render: <PasswordCheckList password={passwordValue}
                                       confirmPassword={confirmPasswordValue}/>,
            show: !!!id
        },

    ]


    async function getValuesForm() {
        let data = await form.getFieldsValue()
        if (id) {
            delete data['username']
        }

        return data
    }


    async function handleSubmit() {
        Modal.confirm({
            title: 'Tem certeza que deseja fazer está ação?',
            content: 'Ao inserir ou editar dados cadastrais lembre-se de confirmar e revisar tudo antes de finalizar',
            icon: <ExclamationCircleFilled/>,
            async onOk() {
                try {
                    setFormIsLoading(true)
                    let data = await getValuesForm()
                    if (id) {
                        const response = await api.put(formatPath(API_PATHS.USER_DETAIL, {id: id}), data);
                        ToastNotification('Usuário atualizado com sucesso')
                        // navigate(PATHS.USER)
                    } else {
                        const response = await api.post(API_PATHS.USERS, data);
                        ToastNotification('Usuário criado com sucesso')
                        navigate(PATHS.USER)
                    }
                } catch (error) {
                    BuildFieldErrorsDict(error, form.setFields, false)
                } finally {
                    setFormIsLoading(false);
                }
            },
        });


    }

    async function handleDelete() {
        try {
            setFormIsLoading(true)
            const response = await api.delete(formatPath(API_PATHS.USER_DETAIL, {id: id}));
            ToastNotification('Usuário excluido com sucesso')
            navigate(PATHS.USER)

        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, false)
        } finally {
            setFormIsLoading(false);
        }
    }

    const isLoading = useMemo(() => isLoadingUser, [isLoadingUser])
    const initialValuesForm = useMemo(() => {
        if (user.id) {
            const data = {...user}
            if (!user.permission) {
                data.permission = []
            }
            return data
        }
        return {}
    }, [user])

    return {
        isLoading,
        formItems,
        handleSubmit,
        handleDelete,
        getValuesForm,
        form,
        initialValuesForm,
        setFormIsLoading,
        formIsLoading,
        user
    }
}