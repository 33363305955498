import React from 'react';
import {Form} from "antd";
import InputNumber from "../InputNumber";
import FormItem from "./FormItem";

export default function InputNumberFormField({form, ...props}) {
    return (
        <FormItem {...form}>
            < InputNumber
                {...props}
            />
        </FormItem>
    )
}