import React, {createContext, useContext, useEffect, useState} from 'react';
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {useLocation} from "react-router-dom";

const LayoutContext = createContext();

export function LayoutProvider({children}) {
    const [collapsed, setCollapsed] = useState(false);
    const [floatMenu, setFloatMenu] = useState(false);
    const {md} = useBreakpoint();
    const location = useLocation()

    useEffect(() => {
        setFloatMenu(!md)
        setCollapsed(!md)
    }, [md, location])

    function changeCollpasedButton() {
        setCollapsed(!collapsed)
    }

    return (
        <LayoutContext.Provider
            value={{changeCollpasedButton, collapsed, floatMenu}}>
            {children}
        </LayoutContext.Provider>
    )
}

export default function useLayoutContent() {
    return useContext(LayoutContext);
}