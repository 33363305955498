import React, {useState} from 'react';
import {Input, Space} from "antd";
import Button from "./Button";
import {SearchOutlined} from "@ant-design/icons";
import DrawerSelectTableField from "./DrawerSelectTableField";
import Select from "./Select";
import {generateSelectOptions} from "../../utils/Utils";

export default function InputSelectTableField({
                                                  onChange,
                                                  mode,
                                                  columns,
                                                  request,
                                                  title,
                                                  key_label = 'id',
                                                  description_label,
                                                  value,
    disabled,
                                                  ...props
                                              }) {
    const [open, setOpen] = useState(false)


    const table = {
        columns,
        request: request,
    }


    function onOpen() {
       !disabled && setOpen(true)
    }

    function handleValue() {
        if (mode === 'multiple') {
            return generateSelectOptions(value, description_label)
        } else if (value) {
            return value[description_label]
        }
    }

    return (
        <>
            <Space.Compact
                style={{
                    width: '100%',
                }}

            >
                <Select
                    allowClear
                    notFoundContent={null}
                    suffixIcon={null}
                    mode={mode}
                    disabled={disabled}
                    onChange={(valueSelect, option) => {
                        if (mode === 'multiple') {
                            const valuesFiltered = value.filter((item) => {
                                if (valueSelect.includes(item.id)) {
                                    return item
                                }
                            })
                            onChange?.(valuesFiltered)
                        } else if (!valueSelect) {
                            onChange?.(valueSelect)
                        }
                    }}
                    onClick={onOpen}
                    value={handleValue()} {...props}/>
                <Button
                    disabled={disabled}
                    onClick={onOpen}
                    type="primary"><SearchOutlined/></Button>

            </Space.Compact>
            <DrawerSelectTableField mode={mode} value={value}
                                    onChange={onChange} title={title}
                                    table={table} open={open}
                                    setOpen={setOpen}/>
        </>
    )
}