export const PATHS = {
    ROOT: '/',
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot_password',
    RESET_PASSWORD: '/reset_password/:uid/:token',
    REGISTER: '/register',


    POLL: '/poll',
    CREATE_POLL: '/poll/create',
    UPDATE_POLL: '/poll/:id',


    CREATE_INTERVIEWER: '/poll/:id/interviewer/create',
    UPDATE_INTERVIEWER: '/poll/:id/interviewer/:id_interviewer',


    ADMIN: '/admin',
    CREATE_ADMIN: '/admin/create',
    UPDATE_ADMIN: '/admin/:id',

    CLIENT: '/client',
    CREATE_CLIENT: '/client/create',
    UPDATE_CLIENT: '/client/:id',




    USER: '/user',
    CREATE_USER: '/user/create',
    UPDATE_USER: '/user/:id',


    CONFIG_MARKUP: '/config_markup',



}

export const TAB_POLL_PATH = {
    DETAIL: 'detail',
    FORM: 'form_build',
    INTERVIEWERS_ALLOCATION: 'interviewers_allocation',
    DASHBOARD: 'dashboard',
    POLL_RESPONSES: 'responses',
    SPOT: 'spot',
}

export const TAB_EMPLOYEE_PATH = {
    SERVERS: 'servers',
    USERS: 'users',
}




