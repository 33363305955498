import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    Circle,
    MapContainer,
    Marker,
    TileLayer
} from "react-leaflet";
import styled from 'styled-components'

const StyledMapContainer = styled(MapContainer)`
    height: ${({height}) => height ? height : '400px'};
`

export default function Map({
                                items,
                                center = [-7.030364558707033, -37.27970123291016],
                                onChange,
                                limitArea = 0,
                                position,
    markerProps,
                                currentMarker = null,
                                zoom = 17,
                                ...props
                            }) {

    // const [currentMarker, setCurrentMarker] = useState(center)
    const [map, setMap] = useState(null)
    const markerRef = useRef(null)

    useEffect(() => {
        if (map && currentMarker) {
            map.setView(currentMarker.latlng)
        }
    }, [currentMarker])

    // useEffect(() => {
    //     if (map && position) {
    //         setCurrentMarker(position)
    //     }
    // }, [position])


    // const eventHandlers = useMemo(
    //     () => ({
    //         // dragend() {
    //         //     const marker = markerRef.current
    //         //     if (marker != null) {
    //         //         setCurrentMarker(marker.getLatLng())
    //         //     }
    //         // },
    //         click(e) {
    //
    //             setCurrentMarker(e.latlng)
    //             markerRef.current = e.target.options.id
    //             // console.log(e.target.options.id)
    //         },
    //     }),
    //     [],
    // )


    return (
        <StyledMapContainer center={center} zoom={zoom}
                            ref={setMap}
                            scrollWheelZoom={true}
                            {...props}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
            {/*<Circle center={currentMarker} pathOptions={{fillColor: 'blue'}}*/}
            {/*        radius={limitArea}/>*/}
            {/*<Marker*/}
            {/*    // draggable={true}*/}
            {/*    eventHandlers={eventHandlers}*/}
            {/*    position={[-7.0367998,-37.301084]}*/}
            {/*    ref={markerRef}/>*/}
            {items.map(
                ({id, location}) => (
                    <Marker
                        style={{color: 'red'}}
                        key={id}
                        id={id}
                        eventHandlers={markerProps}
                        position={[location.latitude, location.longitude]}
                        ref={markerRef}
                    >
                        {currentMarker?.target?.options?.id === id &&
                            <Circle center={[currentMarker.latlng.lat, currentMarker.latlng.lng]}
                                    pathOptions={{fillColor: 'blue'}}
                                    radius={100}/>}
                    </Marker>
                )
            )}

        </StyledMapContainer>

    );
}
