import React, {useMemo, useState} from 'react';
import {
    BuildFieldErrorsDict, DateFormat, formatPath, ParseDate,
} from "../../../utils/Utils";
import {Col, Form as FormAnt, Row, Typography} from "antd";
import {API_PATHS} from "../../../constans/ApiPaths";
import api from "../../../services/api";
import {
    ToastNotification
} from "../../../components/feedback/ToastNotification";
import {PATHS, TAB_POLL_PATH} from "../../../constans/Paths";
import {useNavigate} from "react-router-dom";
import {
    FIELDS_NAMES
} from "../../../components/data_entry/form_field/FormBuild";


const FORM_NAMES = {
    HAS_LOCATION: 'has_location',
    TITLE: 'title',
    START_DATE: 'start_date',
    NUMBERS_OF_INTERVIEWS: 'numbers_of_interviews',
    DESCRIPTION: 'description',
    STARTED: 'started',
}
export default function usePollForm(poll = null) {


    const [form] = FormAnt.useForm();
    const [formIsLoading, setFormIsLoading] = useState();

    const navigate = useNavigate()


    const formItems = [
        {
            field_type: FIELDS_NAMES.SWITCH,
            col: {xs: 24},
            show: !!!poll,
            form: {
                label: 'A pesquisa possui localização?',
                name: FORM_NAMES.HAS_LOCATION,
            },

        },
        {
            field_type: FIELDS_NAMES.SWITCH,
            col: {xs: 24},
            show: poll,
            disabled: !poll?.is_valid ||poll?.started,
            form: {
                label: <Typography.Title level={3}>Iniciar
                    pesquisa</Typography.Title>,
                name: FORM_NAMES.STARTED,
                help: <Row>
                    <Col xs={24}>
                        <Typography.Text
                                strong>Observações:</Typography.Text>
                    </Col>
                    {!poll?.started && <Col xs={24}>
                        <Typography.Text style={{color: 'red'}}>
                         - A pesquisa
                            so poderá ser iniciada quando já
                            possuir um formulário e quando todas as pesquisas
                            sejam
                            distribuidas para os entrevistadores.
                        </Typography.Text>
                    </Col>}
                    <Col xs={24}>
                        <Typography.Text style={{color: 'red'}}>
                         - Uma pesquisa iniciada só pode ser finalizada ao responder todas as entrevistas.</Typography.Text>
                    </Col>
                </Row>
            },

        },
        {
            field_type: FIELDS_NAMES.INPUT,
            // col: {lg: 14, md: 12, xs: 24},
            col: {lg: 20, xs: 24},
            form: {
                label: 'Titulo',
                name: FORM_NAMES.TITLE,

                rules: [{
                    required: true,
                    message: 'Titulo é obrigatório'
                }]
            },
            disabled: poll?.started
        },
        // {
        //     field_type: FIELDS_NAMES.DATE,
        //     col: {lg: 6, md: 12, xs: 24},
        //     form: {
        //         label: 'Data de inicio',
        //         name: FORM_NAMES.START_DATE,
        //         help: 'Essa data não inicia pesquisa.',
        //         rules: [{
        //             required: true,
        //             message: 'Data de inicio é obrigatório',
        //
        //         }]
        //     },
        //     disabled: poll?.started
        // },

        {
            field_type: FIELDS_NAMES.INPUT_NUMBER,
            col: {lg: 4, xs: 24},
            min: 1,
            form: {
                label: 'Quantidade de entrevistas',
                name: FORM_NAMES.NUMBERS_OF_INTERVIEWS,

                rules: [{
                    required: true,
                    message: 'Quantidade de entrevistas é obrigatório'
                }]
            },
            disabled: poll?.started
        },
        // {
        //     field_type: FIELDS_NAMES.TEXT_AREA,
        //     col: {xs: 24},
        //     form: {
        //         label: 'Descrição',
        //         name: FORM_NAMES.DESCRIPTION,
        //
        //     },
        //
        // },


    ]


    async function getValuesForm() {
        let data = await form.getFieldsValue()
        data = {
            ...data,
            [FORM_NAMES.START_DATE]: DateFormat(data[FORM_NAMES.START_DATE], 'YYYY-MM-DD')
        }


        return data
    }

    const InitialValues = useMemo(() => {//useMemo usado para não ficar atualizando o formBuild
        if (poll) {
            return {
                ...poll,
                [FORM_NAMES.START_DATE]: ParseDate(poll[FORM_NAMES.START_DATE])
            }
        }

        return null
    }, [poll])

    function isLoadingSelects() {
        return false
    }

    async function handleSubmit() {
        try {
            setFormIsLoading(true)
            let data = await getValuesForm()
            if (poll?.id) {
                const response = await api.put(formatPath(API_PATHS.POLL_DETAIL, {id:poll?.id}), data);
                ToastNotification('Pesquisa atualizada com sucesso')
                window.location.reload();
                navigate(formatPath(PATHS.UPDATE_POLL, {
                    id: response.data.id,
                    tab_id: TAB_POLL_PATH.FORM
                }))
                // navigate(PATHS.USER)
            } else {
                const response = await api.post(API_PATHS.POLL, data);
                ToastNotification('Pesquisa criado com sucesso')
                navigate(formatPath(PATHS.UPDATE_POLL, {
                    id: response.data.id,
                    tab_id: TAB_POLL_PATH.DETAIL
                }))
            }

        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, false)
        } finally {
            setFormIsLoading(false);
        }
    }


    async function handleDelete() {
        try {
            setFormIsLoading(true)
            let data = await getValuesForm()
            const response = await api.delete(formatPath(API_PATHS.POLL_DETAIL, {id:poll?.id}));
            ToastNotification('Pesquisa deletada com sucesso')
            navigate(PATHS.POLL)

        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, false)
        } finally {
            setFormIsLoading(false);
        }
    }


    return {
        isLoadingSelects,
        formItems,
        getValuesForm,
        handleDelete,
        handleSubmit,
        InitialValues,
        form,
        setFormIsLoading,
        formIsLoading
    }
}