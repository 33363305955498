import React, {useRef, useState} from 'react';

import styled from 'styled-components'
import {
    ReactComponent as DraggableIcon
} from "../../../../assests/icons/draggable.svg";
import {Col, Row, Space, Typography} from "antd";
import QuestionHeader from "./QuestionHeader";

const Container = styled.div`
  font-size: 18px;
  font-weight: bold;
  background-color: white;
  gap: 8px;
  border: 1px solid rgb(181 176 176 / 45%);
  border-radius: 4px;
  margin-bottom: 4px;
  transition: 0.2s;


  .DragIconContainer {
    width: 100%;
    padding: 0 16px;

  }

  &.hide {
    //width: 0px;
    //visibility: hidden;
    display: none;
  }


`
const ContainerIcon = styled.div`
  height: 100%;
  width: 0;
  padding: 0;
  transition: 0.2s;
  cursor: grabbing;
  background-color: beige;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
  }

`

const Body = styled.div`
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  //background-color: red;
`

const DraggableElemente = styled.div`
  padding: 16px 16px;
  width: 500px;
  border: 1px dashed black;
  background-color: aquamarine;
  gap: 16px;
  position: fixed;
  top: -100px;
`


export default function QuestionContainer({
                                              children,
                                              question,
                                              onDragEnd, onDragStart,
                                              onDelete,
                                              onChange,
                                              statement,

                                              ...props
                                          }) {

    const [draggable, setDraggable] = useState(false)
    const containerRef = useRef()
    const dragRef = useRef()

    function handleDragStart(e) {
        onDragStart?.(e)
        setDraggable(false)

        e.dataTransfer.setData('currentSequence', question.sequence)
        e.dataTransfer.setDragImage(dragRef.current, 0,0)
        setTimeout(() => {
            containerRef.current.classList.add('hide')
        }, 100)


    }

    function handleDragEnd(e) {
        onDragEnd?.(e)
        // e.dataTransfer.setData('move', false)
        containerRef.current.classList.remove('hide')
    }

    return (
    <>
        <Container ref={containerRef}
                   onDragStart={handleDragStart}
                   onDragEnd={handleDragEnd}
                   draggable={draggable} {...props}
                   data-question-sequence={question.sequence}>

            <Row>
                <Col
                    flex={1}
                    onMouseEnter={() => setDraggable(true)}
                    onMouseLeave={() => setDraggable(false)}
                >
                    <ContainerIcon className={'DragIconContainer'}>
                        <DraggableIcon/>
                    </ContainerIcon>
                </Col>
                <Col xs={21}>
                    <Body>
                        <Row gutter={[16,16]}>
                            <Col xs={24}>  <QuestionHeader
                                        onChange={onChange}
                                        onDelete={onDelete} question={question}/></Col>
                            <Col xs={24}>{children}</Col>
                        </Row>


                    </Body>
                </Col>
            </Row>
        </Container>
        <DraggableElemente ref={dragRef}> <Typography.Title>{question.sequence} - Pergunta</Typography.Title></DraggableElemente>
</>
    )
}
