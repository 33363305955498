import React from 'react';
import CheckboxField from "../CheckboxField";
import FormItem from "./FormItem";

export default function CheckboxFormField({children, form, ...props}) {
    return (
        <FormItem valuePropName={"checked"} {...form} label={' '}>
            <CheckboxField label={form.label} {...props}/>
        </FormItem>
    )
}