export const USER_PERMISSIONS_CHOICES = [
    {id: 'admin', value: "Administrador"},
    {id: 'interview', value: "Pesquisador"},
]


export const BAR = 'bar'
export const GRAPHICS_PRINT_CHOICES = [
    {id: BAR, value: "Barra"},
    {id: 'pie', value: "Pizza"},
]

export const INDUZIDA = 'Induzida'
export const ESPONTANEA = 'Espontanea'

export const CONFIG_MARKUP_TYPE_IDENTIFY = [
    {id: 'Senha', value: "Senha"},
    {id: 'Biometria', value: "Biometria"},
]

export const EMPLOYEE_GENDER_CHOICES = [
    {id: 'Masculino', value: "Masculino"},
    {id: 'Feminino', value: "Feminino"},
]