import React from 'react';
import Datepicker from "../Datepicker";
import FormItem from "./FormItem";

export default function DatepickerFormField({form, ...props}) {

    return (
        <FormItem {...form}>
            < Datepicker
                {...props}
            />
        </FormItem>
    )
}