import React from 'react';
import {Form, Input} from "antd";
import FormItem from "./FormItem";

export default function PasswordFormField({form, ...props}) {
    return (
        <FormItem {...form}>
            <Input.Password {...props}/>
        </FormItem>
    )
}