import React, {useEffect} from 'react';
import {Col, Row} from "antd";
import InputFormField from "./InputFormField";
import Form from "./Form";
import SelectFormField from "./SelectFormField";
import {ToastNotification} from "../../feedback/ToastNotification";
import DatepickerFormField from "./DatepickerFormField";
import LoadingParent from "../../feedback/LoadingParent";
import CheckboxFormField from "./CheckboxFormField";
import CheckboxGroupFormField from "./CheckboxGroupFormField";
import LocationMapFormField from "./LocationMapFormField";
import AutoCompleteFormField from "./AutoCompleteFormField";
import InputNumberFormField from "./InputNumberFormField";
import TextAreaFormField from "./TextAreaFormField";
import FormItem from "./FormItem";
import PasswordFormField from "./PasswordFormField";
import InputSelectTableFormField from "./InputSelectTableFormField";
import SwitchFormField from "./SwitchFormField";


export const FIELDS_NAMES = {
    INPUT: 'Input',
    PASSWORD_INPUT: 'PasswordInput',
    INPUT_NUMBER: 'InputNumber',
    TEXT_AREA: 'TextArea',
    DATE: 'Date',
    SELECT: 'Select',
    CHECKBOX: 'Checkbox',
    CHECKBOX_GROUP: 'CheckboxGroup',
    LOCATION_MAP: 'LocationMap',
    AUTO_COMPLETE: 'AutoComplete',
    CUSTOM_FIELD: 'CustomField',
    INPUT_SELECT_TABLE: 'InputSelectTable',
    SWITCH: 'Switch',
}

export default function FormBuild({
                                      initialValues,
                                      loading,
                                      fields,
                                      footer,
                                      form,
                                      ...props
                                  }) {


    useEffect(() => {
        if (initialValues) {
            console.log('teste')
            // const formatedInitialValues = {};
            // Object.keys(initialValues).forEach((key) => {
            //     formatedInitialValues[key] = initialValues[key]
            //     if (initialValues[key] instanceof Object && Object.keys(initialValues[key]).includes('id')) {
            //         formatedInitialValues[key] = initialValues[key].id
            //     }
            // })//pega o id do foreing key para preencher o select, todo depois testar para ver se com outros componente gera problema
            form?.setFieldsValue(initialValues)
        }

    }, [initialValues])

    function getField({form, field_type, ...field}) {

        switch (field_type) {
            case FIELDS_NAMES.INPUT:
                return <InputFormField form={form} {...field}/>
            case FIELDS_NAMES.PASSWORD_INPUT:
                return <PasswordFormField form={form} {...field}/>
            case FIELDS_NAMES.INPUT_NUMBER:
                return <InputNumberFormField form={form} {...field}/>
            case FIELDS_NAMES.TEXT_AREA:
                return <TextAreaFormField form={form} {...field}/>
            case FIELDS_NAMES.DATE:
                return <DatepickerFormField form={form} {...field}/>
            case FIELDS_NAMES.SELECT:
                return <SelectFormField form={form} {...field}/>
            case FIELDS_NAMES.CHECKBOX:
                return <CheckboxFormField form={form} {...field}/>
            case FIELDS_NAMES.CHECKBOX_GROUP:
                return <CheckboxGroupFormField form={form} {...field}/>
            case FIELDS_NAMES.LOCATION_MAP:
                return <LocationMapFormField form={form} {...field}/>
            case FIELDS_NAMES.AUTO_COMPLETE:
                return <AutoCompleteFormField form={form} {...field}/>
            case FIELDS_NAMES.INPUT_SELECT_TABLE:
                return <InputSelectTableFormField form={form} {...field}/>
            case FIELDS_NAMES.SWITCH:
                return <SwitchFormField form={form} {...field}/>
            case FIELDS_NAMES.CUSTOM_FIELD:
                return <FormItem {...form}>
                    {field.render ?? 'Falta adicionar o field ao chave "render"(MENSAGEM PARA DEVS)'}
                </FormItem>
            default:
                return 'Falta implementar campo no FormBuild(MENSAGEM PARA DEVS)'
        }
    }

    return (
        <Form
            // validateTrigger={'onSubmit'}
            form={form}
            onFinishFailed={() => ToastNotification('Alguns campos são obrigatorio', 'error')}
            {...props}>
            <LoadingParent loading={loading}>
                <Row gutter={[16, 0]}>

                    {fields?.length > 0 && fields.filter(({show = true}) => show).map(({
                                                                                           col,
                                                                                           ...item
                                                                                       }, index) => {
                        const newCol = col ? col : {xs: 24, md: 12}
                        return <Col key={index} {...newCol}>
                            {getField(item)}
                        </Col>
                    })}
                    <Col xs={24}>
                        {footer}
                    </Col>

                </Row>
            </LoadingParent>
        </Form>
    )
}