import React from 'react';

import {Bar as BarAnt} from '@ant-design/plots';

export default function Bar({
                                poll, hiddeLegend,
                                ...props
                            }) {

    function calculatePercent(value) {
        if(!poll?.amount_interviews) return 0
        return (value / poll?.amount_interviews) * 100
    }

    const legend = hiddeLegend ? false : {

        color: {
            position: 'top',
            maxRows: 50,
            // maxCols: 10

        },

    }

    const configBar = {
        xField: 'statement',
        yField: 'amount',

        colorField: 'statement',
        // minHeight: 150,
        // maxHeight: 250,
        // height: 250,

        interaction: {
            tooltip: {
                render: (e, {title, items}) => {
                    return (
                        <div key={title}>


                            {items.map((item) => {
                                const {name, value, color} = item;
                                return (
                                    <div>
                                        <div style={{
                                            margin: 0,
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}>
                                            <div>
                        <span
                            style={{
                                display: 'inline-block',
                                width: 6,
                                height: 6,
                                borderRadius: '50%',
                                backgroundColor: color,
                                marginRight: 6,
                            }}
                        ></span>
                                                <span>{title}</span>
                                            </div>
                                            <b>{calculatePercent(value)}%
                                                - {value}</b>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                },
            },
        },

        style: {
            // fontSize: 50,
            minWidth: 10,
            fontWeight: 'bold'
        },
        legend: legend,
        label: {
            text: (originData) => `${calculatePercent(originData.amount).toFixed(2)}%`,

            style: {
                textAnchor: (originData) => calculatePercent(originData.amount) < 23 ? 'start' : 'right',
                fill: 'black',
                fontSize: 20,
                fontWeight: 'bold',
            }
        },
        scale: {
            y: {
                domain: [0, poll?.amount_interviews],
            },
        },
        axis: {
            y: {
                // labelFontSize: 16,

                labelFontWeight: 'bold',
                labelFill: 'black',
                labelFormatter: (d) => `${calculatePercent(d).toFixed(0)}%`
                /// max
            },
            x: {
                textAnchor: 'right',
                labelFormatter: (value) => {
                    if (value.length > 20) {
                        return `${value.slice(0, 15)}...`
                    }
                    return value
                },
                labelFontSize: 14,
                // width: 10,
                titleStrokeOpacity: 0,
                labelFontWeight: 'bold',
            }
        },
    }

    return (

        <BarAnt {...configBar} {...props}/>


    )
}
