import React, {useMemo, useState} from 'react';
import {Col, Divider, Popconfirm, Row, Typography} from "antd";

import dayjs from "dayjs";
import usePollForm from "../hooks/usePollForm";
import api from "../../../services/api";
import {BuildFieldErrorsDict, cfpMask, formatPath} from "../../../utils/Utils";
import {API_PATHS} from "../../../constans/ApiPaths";
import {
    ToastNotification
} from "../../../components/feedback/ToastNotification";
import {PATHS, TAB_POLL_PATH} from "../../../constans/Paths";
import {useNavigate} from "react-router-dom";
import FormBuild from "../../../components/data_entry/form_field/FormBuild";
import Button from "../../../components/data_entry/Button";
import useCreateBreadcrumbs from "../../../hooks/useCreateBreadcrumbs";


export default function PageTabUpdatePoll({poll}) {

    const {
        isLoadingSelects,
        formItems,
        handleDelete,
        handleSubmit,
        form,
        formIsLoading,
        InitialValues
    } = usePollForm(poll)


    const navigate = useNavigate()


    return (
        <>
            <FormBuild disabled={formIsLoading} loading={isLoadingSelects()} onFinish={handleSubmit}
                // onValuesChange={handleFields}
                initialValues={InitialValues}
                       fields={formItems} form={form}
                       footer={
                           <Row justify={'end'} gutter={[8, 8]}>
                               <Col>
                                   <Button loading={formIsLoading}
                                           onClick={() => navigate(PATHS.POLL)}
                                           type={"default"}>Cancelar</Button>
                               </Col>
                               <Col>
                                   <Popconfirm
                                       title={`Excluir pesquisa`}
                                       description="Tem certeza que deseja realizar está ação?"
                                       onConfirm={handleDelete}
                                       okText="Sim"
                                       okButtonProps={{loading: formIsLoading}}
                                       cancelText="Cancelar"
                                   >
                                       <Button danger
                                               disabled={poll?.started}
                                               loading={formIsLoading}>Excluir</Button>
                                   </Popconfirm>

                               </Col>
                               <Col>
                                   <Button loading={formIsLoading}
                                           disabled={poll?.started}
                                           htmlType="submit">Salvar</Button>
                               </Col>
                           </Row>
                       }/>

        </>
    );
}
