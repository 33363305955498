import React from 'react';
import { InputNumber as InputNumberAntd} from "antd";
import styled from 'styled-components'

const StyledInputNumberAnt = styled(InputNumberAntd)`
  width: 100%
`

export default function InputNumber({...props}){
    return (
        <StyledInputNumberAnt {...props}/>
    )
}