import React, {useRef} from 'react';
import styled from "styled-components";
import {Table as TableAnt} from "antd";

// import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";


import {maskIntNumber, valueOrEmptyCaractere} from "../../utils/Utils";
import Empty from "./Empty";

const StyledEmpty = styled(Empty)`
  padding: 73px;
`
const StyledTable = styled(TableAnt)`
  margin-top: 16px;

  //.ant-select-arrow {
  //  color: white;
  //}
  //
  // .ant-select-open .ant-select-arrow {
  //   color: #656C70;
  // }
  //
  //
  tr th.ant-table-column-has-sorters:hover {
    color: white;
  }

  tr > td.ant-table-cell-row-hover {
    ${({hasClick}) => {
      if (!hasClick) {
        return ' background-color: unset !important;'
      }
    }};

  }

  tr:hover td {
    cursor: ${({hasClick}) => hasClick ? 'pointer' : 'default'};

    //background: #323535 !important;
  }

  //
  // .ant-pagination-item-link {
  //   background: transparent;
  //   border: none;
  //   color: white;
  // }

`


export default function Table({
                                  onRowClick,
                                  tableParams,
                                  noPagination = false,
                                  columns,
                                  noScroll = false,
                                  showQuickJumper = true,
                                  showSizeChanger = true,
                                  tableHeightDiff = 0, //Diminui em pixels do scrool da tabela
                                  ...props
                              }) {
    // const {isPhone, isTablet} = useSystemBreakpoint();
    const tableRef = useRef();


    //
    // const getScrollWidth = () => {
    //     let distance_table_2_botton = 120
    //     if (!isTablet && !isPhone) {
    //         distance_table_2_botton += 40
    //     }
    //     let distance_table_2_top = tableRef?.current?.getBoundingClientRect().top
    //     return window.innerHeight - distance_table_2_top - distance_table_2_botton
    // }

    function getObjectValue(obj, columnName) {
        // debugger

        if (obj && columnName.includes('.')) {
            let names = columnName.split('.')
            let item = obj[names[0]]
            if (item) {
                return getObjectValue(item, names.slice(1,).join('.'))
            }
        }
        return <>{obj[columnName] ? obj[columnName] : '-'}</>
    }

    const _get_columns = () => {
        return columns.map((column) => {
            if (tableParams && tableParams.sorter && column.dataIndex === tableParams.sorter.field) {
                column['defaultSortOrder'] = tableParams.sorter.order
            }
            if (!column.render) {
                column.render = (value, record) => {

                    return getObjectValue(record, column.dataIndex)

                }
            }
            return column
        })
    }
    return (
        <StyledTable

            ref={tableRef}
            size={'small'}
            showSorterTooltip={false}
            hasClick={!!onRowClick}
            onRow={(record) => {
                return {
                    onClick: (e) => onRowClick && onRowClick(record)
                    // onDoubleClick: (e) => onRowClick && onRowClick(record)
                }
            }}
            pagination={noPagination ? false : {

                ...tableParams.pagination,
                // hideOnSinglePage:true,
                showQuickJumper: showQuickJumper,
                locale: {
                    jump_to: 'ir para',
                    page: '',
                },
                // position: isPhone && ["bottomCenter"],
                showTotal: (total, range) => `${maskIntNumber(total)} resultados encontrados`,
                // showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                size: 'small',
                showSizeChanger: showSizeChanger
            }}
            locale={{
                sortTitle: '',
                emptyText: <StyledEmpty/>,

            }}
            scroll={{x: 1200}}
            columns={_get_columns()}
            {...props}
        />
    )
}