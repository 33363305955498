import styled from 'styled-components';
import {Layout as LayoutAnt, Row, Space, Spin} from "antd";
import Menu from "../components/layout/Menu";
import ActionBar from "../components/layout/ActionBar/ActionBar";
import {Outlet} from "react-router-dom";
import Breadcrumb from "../components/data_display/Breadcrumb";
import {useUserContext} from "../context/UserContext";
import DevTools from "../components/other/DevTools";

const {Content: ContentAnt} = LayoutAnt;

const Layout = styled(LayoutAnt)`
  height: 100vh;
  width: 100vw;
`
const ScrollContent = styled(ContentAnt)`
  height: 100%;
  overflow-y: auto;
  padding: 16px;
`

const Content = styled(ContentAnt)`
  height: min-content;
  padding: 16px;
  background: white;
  overflow: hidden;
  border-radius: 8px;
`

export default function LayoutSystem({content}) {
    const {refreshContent, client, refresh} = useUserContext()
    return (
        <Layout>

            <Menu/>
            <LayoutAnt>
                {/*<DevTools/>*/}
                <ActionBar/>

                {(!refreshContent || !refresh) &&
                    <ScrollContent>
                        <Content>
                            <Breadcrumb/>
                             {client ? <Outlet/>: <Row  align={'center'}><Spin spinning/></Row>}

                        </Content>
                    </ScrollContent>}
            </LayoutAnt>
        </Layout>

    );
}