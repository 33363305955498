import React, {useMemo, useState} from 'react';
import {Col, Row, Space, Radio} from "antd";
import {CSVLink} from 'react-csv';
import Button from "../../../components/data_entry/Button";
import {
    FilePdfOutlined,
    PieChartOutlined,
    FundOutlined,
    BarChartOutlined,
    ExportOutlined
} from '@ant-design/icons'
import {BuildFieldErrorsDict, formatPath} from "../../../utils/Utils";
import {FlexContainer} from "../../../components/other/StyledComponents";
import useDefaultGetRequest from "../../../hooks/useDefaultGetRequest";
import {API_PATHS} from "../../../constans/ApiPaths";
import Loading from "../../../components/feedback/Loading";
import QuestionResult from "./components/QuestionResult";

import ResultPrintModal from "./components/ResultPrintModal";
import IntroductionResult from "./components/IntroductionResult";
import api from "../../../services/api";
import Empty from "../../../components/data_display/Empty";

export default function TabDashboardPoll({poll}) {
    const [graficType, setGraficType] = useState('bar')
    const [openPrintModal, setOpenPrintModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const {
        data: results,
        isLoading: isLoadingResults
    } = useDefaultGetRequest({
        url: formatPath(API_PATHS.POLL_RESULTS, {id: poll.id}),
        dataDefault: []
    })

    async function handleExportTable() {
        try {
            setIsLoading(true)
            const response = await api.get(formatPath(API_PATHS.POLL_PRINT_TABLE, {id: poll.id}), {
                responseType: "blob",
                // ...request
            });
            //
            const href = URL.createObjectURL(response.data);
            const tag_a = document.createElement('a');
            tag_a.href = href;
            tag_a.setAttribute('download', `${poll.title}-tabela.pdf`);
            tag_a.click();
            URL.revokeObjectURL(href);

        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        } finally {
            setIsLoading(false)
        }
    }

    const result_csv = useMemo(() => {
        const values = []
        results.forEach((item) => {
            item.results.forEach((result, index) => {
                values.push([index === 0 ? item.question.statement : '', index === 0 ? item.question.type : '', result.statement, result.amount, result.percent])
            })
        })
        return values
    }, [results])
    const headers = ['Pergunta', 'Tipo', 'Opção', 'Quantidade', 'Porcentagem %']
    if (isLoadingResults) return <Loading/>
    if(results.length == 0 ) return <Empty/>
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <IntroductionResult poll={poll}/>
                </Col>
                <Col xs={24}>
                    <FlexContainer justify={'space-between'} wrap={'wrap'}
                                   gap={16}>
                        <Radio.Group options={[
                            {
                                label:
                                    <Space><BarChartOutlined/> Barra</Space>,
                                value: 'bar',
                            },
                            {
                                label:
                                    <Space><PieChartOutlined/> Pizza</Space>,
                                value: 'pie',
                            },
                        ]} optionType="button"
                                     buttonStyle="solid" value={graficType}
                                     onChange={({target: {value}}) => setGraficType(value)}/>
                        <Space wrap><CSVLink filename={`${poll.title}.csv`}
                                             headers={headers} target="_blank"
                                             data={result_csv}>
                            <Button loading={isLoading} block type={'default'}
                                // onClick={() => setOpenPrintModal(true)}?
                                    icon={<ExportOutlined/>}>Exportar
                                csv</Button></CSVLink>
                            <Button loading={isLoading} block type={'default'}
                                    onClick={handleExportTable}
                                    icon={<FilePdfOutlined/>}>Exportar
                                tabela</Button>
                            <Button loading={isLoading} block type={'default'}
                                    onClick={() => setOpenPrintModal(true)}
                                    icon={<FundOutlined/>}>Exportar
                                Gráfico</Button>
                        </Space>

                    </FlexContainer>
                </Col>
                {
                    results?.map((result, index) => (

                        <Col xs={24} key={index}>

                            <QuestionResult type={graficType}
                                            poll={poll} {...result}/>
                        </Col>

                    ))
                }


            </Row>
            <ResultPrintModal poll={poll} results={results}
                              open={openPrintModal}
                              setOpen={setOpenPrintModal}/>
        </>
    );
}
