import React, {useEffect, useRef, useState} from 'react';
import InputField from "../../../../components/data_entry/InputField";
import {Col, Popconfirm, Row, Space, Typography} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import styled from 'styled-components'
import {PATHS} from "../../../../constans/Paths";
import InputFormField
    from "../../../../components/data_entry/form_field/InputFormField";


const DeleteContainer = styled.div`
  font-size: 18px;
  padding: 2px 8px;
  cursor: pointer;

`


export default function QuestionHeader({
                                           question,
                                           onDelete,
                                           onChange,
                                           ...props
                                       }) {
    const [value, setValue] = useState(question.statement)
    const hasChangeRef = useRef(false)

    useEffect(() => {
        hasChangeRef.current = true
    }, [value])

    function handleBlur({target: {value: internalValue}}, index) {
        if (hasChangeRef.current) {
            onChange({...question, statement: value})
            hasChangeRef.current = false
        }
    }

    return (

        <Row gutter={[8, 8]}>

            <Col> <Typography.Title style={{marginBottom: 0}}
                                    level={3}>
                {question.sequence} -</Typography.Title></Col>
            <Col flex={1}><InputFormField form={
                {
                    initialValue:value,
                    name: question.id || question.key,
                    rules: [{
                        required: true,
                        message: 'Essa informação é obrigatória'
                    }]
                }
            } defaultValue={value}
                                          value={value}
                                          onChange={({target: {value}}) => {

                                              setValue(value)
                                          }}
                                          onBlur={handleBlur}/> </Col>
            <Col>
                <Popconfirm
                    title={`excluir item`}
                    description="Tem certeza que deseja realizar está ação?"
                    onConfirm={() => onDelete(question.sequence)}
                    okText="Sim"
                    cancelText="Cancelar"
                >
                    <DeleteContainer><DeleteOutlined/></DeleteContainer>
                </Popconfirm>
            </Col>
        </Row>


    )
}
