import React, {useEffect, useState} from 'react';
import {Col, Drawer, Row, Typography} from "antd";
import Search from "./Search";
import Table from "../data_display/Table";
import useTable from "../../hooks/useTable";
import {ToastNotification} from "../feedback/ToastNotification";

export default function DrawerSelectTableField({
                                                   table,
                                                   open,
                                                   setOpen,
                                                   title,
                                                   mode, value,
                                                   onChange,
                                                   ...props
                                               }) {
    const [searchRequest, setSearchRequest] = useState('');
    const [search, setSearch] = useState('');


    const {
        tableData,
        isLoading,
        tableParams,
        handleRequestTable,
        handleTableChange
    } = useTable({
        search: searchRequest,
        request: table.request,
        hasPagination: true,
        useHandleRequestTable: false,
    })

    useEffect(() => {
        if (open) {
            handleRequestTable()
        }
    }, [searchRequest, open])


    useEffect(() => {
        if (!open) {
            setSearch('')
            setSearchRequest('')
        }
    }, [open])


    return (
        <Drawer width={700} title={title} open={open}
                onClose={() => setOpen?.(false)}>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Search value={search}
                            onChange={({target: {value}}) => setSearch(value)}
                            onSearch={(value) => setSearchRequest(value)}/>
                </Col>

                <Col xs={24}>
                    <Typography.Text type={'secondary'}>Clique em alguma opção
                        da tabela:</Typography.Text>
                    <Table
                        columns={table.columns}
                        scroll={{x: 0}}
                        onChange={handleTableChange}
                        tableParams={tableParams}
                        loading={isLoading}
                        noScroll
                        onRowClick={(record) => {
                            if (mode === 'multiple') {
                                const alreadyHasValue = value.find(({id}) => id === record.id)
                                if (alreadyHasValue) {
                                    ToastNotification('Item já foi selecionado, por favor escolha outro.', 'warning')
                                } else {
                                    onChange?.([...value, record])
                                    setOpen?.(false)
                                }
                            } else {
                                onChange?.(record)
                                setOpen?.(false)
                            }


                        }}
                        dataSource={tableData}
                    />
                </Col>
            </Row>
        </Drawer>

    )
}