import React from 'react';
import AutoCompleteField from "../AutoCompleteField";
import FormItem from "./FormItem";

export default function AutoCompleteFormField({form, ...props}) {
    return (
        <FormItem {...form}>
            < AutoCompleteField
                {...props}
            />
        </FormItem>
    )
}