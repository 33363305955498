import React from 'react';
import CheckboxField from "../CheckboxField";
import FormItem from "./FormItem";
import CheckboxGroupField from "../CheckboxGroupField";

export default function CheckboxGroupFormField({children, form, ...props}) {
    return (
        <FormItem  {...form}>
            <CheckboxGroupField {...props}/>
        </FormItem>
    )
}