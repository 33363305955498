import React, {useState} from 'react';
import {Col, Divider, Popconfirm, Row, Typography} from "antd";
import {PATHS} from "../../constans/Paths";
import useCreateBreadcrumbs from "../../hooks/useCreateBreadcrumbs";
import Button from "../../components/data_entry/Button";
import FormBuild from "../../components/data_entry/form_field/FormBuild";
import {useNavigate} from "react-router-dom";

import useUserForm from "./hooks/useUserForm";
import ResetUserPasswordModal
    from "./components/ResetUserPasswordModal";


export default function PageUpdateUser() {
    const {
        formItems,
        form,
        handleSubmit,
        formIsLoading,
        initialValuesForm,
        isLoading,
        user, handleDelete
    } = useUserForm()

    const navigate = useNavigate()
    const [openResetPassword, setOpenResetPassword] = useState(false)
    useCreateBreadcrumbs([
        {
            title: 'Usuários',
            link: PATHS.USER
        },
        {
            title: `${user?.get_full_name} `,
        },

    ], [user])


    return (
        <>
            <Typography.Title>{user.get_full_name}</Typography.Title>
            <Divider/>
            <FormBuild initialValues={initialValuesForm}
                       loading={isLoading}
                       onFinish={handleSubmit}
                       fields={formItems} form={form}
                       footer={
                           <Row justify={'end'} gutter={[8, 8]}>
                               <Col>
                                   <Button loading={formIsLoading}
                                           onClick={() => navigate(PATHS.USER)}
                                           type={"default"}>Cancelar</Button>
                               </Col>

                               <Col>
                                   <Button loading={formIsLoading}
                                           onClick={() => setOpenResetPassword(true)}
                                           type={"default"}>Redefinir
                                       senha</Button>
                               </Col>
                               <Col>
                                   <Popconfirm
                                       title={`Excluir usuário`}
                                       description="Tem certeza que deseja realizar está ação?"
                                       onConfirm={handleDelete}
                                       okText="Sim"
                                       okButtonProps={{loading: formIsLoading}}
                                       cancelText="Cancelar"
                                   >
                                       <Button loading={formIsLoading}
                                               danger
                                               type={"default"}>Excluir</Button>
                                   </Popconfirm>
                               </Col>
                               <Col>
                                   <Button loading={formIsLoading}
                                           htmlType="submit">Salvar</Button>
                               </Col>
                           </Row>
                       }/>

            <ResetUserPasswordModal open={openResetPassword}
                                    setOpen={setOpenResetPassword}/>
        </>
    );
}
