import React, {useMemo} from 'react';
import {Col, Row, Tag, Typography,} from "antd";
import {useNavigate} from "react-router-dom";
import Button from "../../../components/data_entry/Button";
import {PlusOutlined} from '@ant-design/icons'
import {PATHS, TAB_POLL_PATH} from "../../../constans/Paths";
import {formatPath, generateFieldsByColumns} from "../../../utils/Utils";
import Table from "../../../components/data_display/Table";
import useTable from "../../../hooks/useTable";
import DefaultListPage from "../../../components/other/DefaultListPage";
import {USER_PERMISSIONS_CHOICES} from "../../../constans/choices";
import {API_PATHS} from "../../../constans/ApiPaths";


export default function TabManagerInterviewrsPoll({poll}) {
    const navigate = useNavigate()
    const columns = [
        {
            title: 'Nome do Entrevistador',
            dataIndex: 'interviewer.get_full_name',
            // sorter: 'interviewer',
            key: 'interviewer',
        },
        {
            title: 'Quantidades alocadas',
            dataIndex: 'number_of_interviews',
            // sorter: 'number_of_interviews',
            key: 'number_of_interviews',
        },


    ]


    const fields = useMemo(() => {
        return `${generateFieldsByColumns(columns)},id`
    }, [])

    const table = {
        columns,
        request: {
            url: API_PATHS.INTERVIEWERS_POLL_POST,
            extraParams: {
                fields: fields,
            },
            body:{
                 poll: poll.id
            }
        },
        onRowClick: (values) => {
            navigate(formatPath(PATHS.UPDATE_INTERVIEWER, {
                id: poll.id,
                id_interviewer: values.id
            }))
        }
    }

    return (
        <Row>
           <Col xs={24}>
                <Typography.Title level={3}>Quantidade de entrevistas disponiveis:  {poll.amount_interviews_remaining_for_distribution} </Typography.Title>
            </Col>
            <Col xs={24}>
                  <DefaultListPage createButton={
            {onClick: () => navigate(formatPath(PATHS.CREATE_INTERVIEWER, {id: poll.id})), disabled: poll?.started}}
                         table={table} columns={columns}/>
            </Col>
        </Row>


    );
}
