import React from "react";
import {Dropdown} from "antd";
import RemixIcon from "../../data_display/RemixIcon";
import {useUserContext} from "../../../context/UserContext";
import {useNavigate} from "react-router-dom";
import {PATHS} from "../../../constans/Paths";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {storeClient} from "../../../utils/Auth";

const MAX_CARACTERES = 15;
export default function ClientSelector(props) {


    const {client, setClient, updateContent, user, get_user} = useUserContext();
    const navigate = useNavigate()

    const {sm} = useBreakpoint();

    function generateClients() {
        return user?.clients?.map((client) => (
            {
                label: client.title.toUpperCase(),
                key: client.id,
            }
        ))
    }

    function handleClickItem({key}) {
         storeClient(key)
        // setClient(user?.clients?.find(({id}) => id === Number(key)))
        window.location.reload()
        // navigate(PATHS.ROOT)
        // get_user()
    }

    function handleShowName() {
        const title = client?.title

        if (sm) {
            return title?.toUpperCase()
        } else if (title?.length > MAX_CARACTERES) {
            return `${title?.toUpperCase().substring(0, MAX_CARACTERES)}...`
        }
        return title?.toUpperCase()

    }
    if(!user || !user.clients || user.clients.length === 1) return null

    return (

        <Dropdown.Button  trigger={['click']} menu={{
            selectable: true,
            defaultSelectedKeys: [client?.id.toString()],
            items: generateClients(), onClick: handleClickItem
        }} placement="bottom"
                         icon={<RemixIcon
                             remixIconName={'ri-building-4-line'}/>}>
            {handleShowName()}
        </Dropdown.Button>


    );
}
