import React from 'react';
import {FlexContainer} from "../components/other/StyledComponents";
import Empty from "../components/data_display/Empty";
import {Result} from "antd";
import Button from "../components/data_entry/Button";
import {PATHS} from "../constans/Paths";
import {useNavigate} from "react-router-dom";

export default function PageNotFound() {
    const navigate = useNavigate()
    return (
        <FlexContainer align={'center'} justify={'center'}>
            <Result
                status="404"
                title="404"
                subTitle="Desculpe, a pagina não foi encontrada."
                extra={<Button onClick={() => navigate(PATHS.POLL)}>Volta para as pesquisas</Button>}
            />


        </FlexContainer>
    );
}
