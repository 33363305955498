import React from 'react';
import styled from 'styled-components'

import {Pie as PieAnt} from '@ant-design/plots';


export default function Pie({
                                poll,
                                results,hiddeLegend,
                                ...props
                            }) {

    function calculatePercent(value) {
        return (value / poll?.amount_interviews) * 100
    }


    const configPie = {
        // xField: 'statement',
        angleField: 'amount',

        colorField: 'statement',


        style: {
            fontSize: 24,
            fontWeight: 'bold'
        },
        tooltip: {
            title: 'statement',
        },
        interaction: {
            tooltip: {
                render: (e, {title, items}) => {
                    return (
                        <div key={title}>


                            {items.map((item) => {
                                const {name, value, color} = item;
                                return (
                                    <div>
                                        <div style={{
                                            margin: 0,
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}>
                                            <div>
                        <span
                            style={{
                                display: 'inline-block',
                                width: 6,
                                height: 6,
                                borderRadius: '50%',
                                backgroundColor: color,
                                marginRight: 6,
                            }}
                        ></span>
                                                <span>{title}</span>
                                            </div>
                                            <b>{calculatePercent(value)}%
                                                - {value}</b>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                },
            },
        },
        label: {
            text: (originData) => {
                const percent = calculatePercent(originData.amount)
                if (percent !== 0) return `${percent.toFixed(2)}%`
                return ''
            },
            // legend: false,
            style: {
                // textAnchor: (originData) => calculatePercent(originData.amount) < 10 ? 'start' : 'right',
                fill: 'black',
                fontSize: 24,
                fontWeight: 'bold',
            }
        },
        scale: {
            y: {
                domain: [0, poll?.amount_interviews],
            },
        },
        legend: {
            color: {
                // title: false,
                position: 'top',
                maxRows: 50,
                // maxCols: 10
            },

        },
        axis: {
            y: {

                labelFormatter: (d) => `${calculatePercent(d).toFixed(0)}%`
                /// max
            },
        },
    }
    return (

        <PieAnt {...configPie} {...props}  />


    )
}
