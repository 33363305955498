import React, {useEffect, useState} from 'react';
import {Tabs, Tooltip, Typography} from "antd";
import {PATHS, TAB_POLL_PATH} from "../../constans/Paths";
import useCreateBreadcrumbs from "../../hooks/useCreateBreadcrumbs";
import useDefaultGetRequest from "../../hooks/useDefaultGetRequest";
import {formatPath} from "../../utils/Utils";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {API_PATHS} from "../../constans/ApiPaths";
import PageTabUpdatePoll from "./update_tabs/PageTabUpdatePoll";
import useQuery from "../../hooks/useQuery";
import PollTag from "../../components/other/PollTag";
import {FlexContainer} from "../../components/other/StyledComponents";
import TabFormBuildPoll from "./update_tabs/TabFormBuildPoll";
import Loading from "../../components/feedback/Loading";
import TabManagerInterviewrsPoll
    from "./update_tabs/TabManagerInterviewrsPoll";
import TabDashboardPoll from "./update_tabs/TabDashboardPoll";
import TabSpotPoll from "./update_tabs/TabSpotPoll";
import TabPollResponse from "./update_tabs/TabPollResponse";


export default function PageUpdatePoll() {
    const querys = useQuery()
    const {id} = useParams()
    const [currentTab, setCurrentTab] = useState()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const tab = querys.get('tab')
        if (tab) {
            setCurrentTab(tab)
        } else {
            querys.set('tab', TAB_POLL_PATH.DETAIL)
            navigate({search: querys.toString()})
        }
    }, [])

    const {
        data: poll,
        isLoading: isLoadingPoll
    } = useDefaultGetRequest({
        redirect: true,
        url: formatPath(API_PATHS.POLL_DETAIL, {id: id})
    })

    useCreateBreadcrumbs([
        {
            title: 'Pesquisas',
            link: PATHS.POLL
        },
        {
            title: `${poll?.title}`,
            //     todo atualizar breadcrumb depois do update
        },

    ], [poll])

    return (<>
            {isLoadingPoll ? <Loading/> : <>
                <FlexContainer gap={12} align={'center'}>
                    <Typography.Title
                        style={{marginBottom: 0}}>{poll?.title} </Typography.Title>
                    <PollTag
                        value={poll.status}/>
                </FlexContainer>
                <Tabs
                    // defaultActiveKey={TAB_EMPLOYEE_PATH.USERS}
                    // type="card"

                    activeKey={currentTab}
                    onChange={(key) => {
                        querys.set('tab', key)
                        navigate({search: querys.toString()})
                        setCurrentTab(key)
                    }}
                    items={[
                        {
                            label: 'Dados da pesquisa',
                            children: <PageTabUpdatePoll poll={poll}/>,
                            key: TAB_POLL_PATH.DETAIL,
                        }, {
                            label: 'Criador de formulário',
                            children: <TabFormBuildPoll poll={poll}/>,
                            key: TAB_POLL_PATH.FORM,
                        },
                        {
                            label: 'Alocação de entrevistadores',
                            children: <TabManagerInterviewrsPoll poll={poll}/>,
                            key: TAB_POLL_PATH.INTERVIEWERS_ALLOCATION,
                        },
                        {
                            label: 'Resultado',
                            children: <TabDashboardPoll poll={poll}/>,
                            key: TAB_POLL_PATH.DASHBOARD,
                        },
                        {
                            label: 'Entrevistas',
                            children: <TabPollResponse poll={poll}/>,
                            key: TAB_POLL_PATH.POLL_RESPONSES,
                        },
                        {
                            label: <Tooltip title={!poll.has_location && 'Essa pesquisa não possui localização'}>Localização</Tooltip>,
                            children: <TabSpotPoll poll={poll}/>,
                            key: TAB_POLL_PATH.SPOT,
                            disabled:!(poll.has_location)
                        },


                    ]}
                />
            </>}
        </>
    );
}
