import React, {useState} from "react";
import styled from 'styled-components';
import {Dropdown, Space} from "antd";
import {useUserContext} from "../../../context/UserContext";
import {UserOutlined, DownOutlined, EditOutlined} from "@ant-design/icons";
import {deleteTokens} from "../../../utils/Auth";
import {useNavigate} from "react-router-dom";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";


const UserProfile = styled.div`
  color: white;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-wrap: nowrap;
  gap: 4px;
  font-size: 16px;
  cursor: pointer;
`


export default function UserDropdown(props) {

    const {user,handleLogout} = useUserContext();
    const navigate = useNavigate()
    const {md} = useBreakpoint();
    const items = [
        // {
        //     label: 'Perfil(validar se vai ter)',
        //     key: '1',
        //     icon: <EditOutlined/>
        // },
        // {
        //     type: 'divider',
        // },
        {
            label: 'Sair',
            onClick: handleLogout,
            key: '2',
        },
        //
    ]



    return (

        <Dropdown
            trigger={['click']}
            menu={{
                items,
            }}
        >
            <UserProfile>
                <Space>
                    <UserOutlined/>
                    {md && user?.get_full_name}
                    <DownOutlined/>
                </Space>
            </UserProfile>
        </Dropdown>


    );
}
