import React from 'react';
import { Form as FromAnt} from "antd";

export default function Form({children, ...props}) {
    return (
        <FromAnt layout={'vertical'}

              scrollToFirstError
              requiredMark={true} {...props} >
            {children}
        </FromAnt>
    )
}