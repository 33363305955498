import React from 'react';
import {Breadcrumb as BreadcrumbAnt} from "antd";
import {useBreadcrumbsContext} from "../../context/BreadcrumbsContext";


export default function Breadcrumb({props}) {
    const {breadcrumbs} = useBreadcrumbsContext();
    return (
        <BreadcrumbAnt items={breadcrumbs} {...props}/>
    )
}