import {UserProvider} from "./UserContext";
import {LayoutProvider} from "./LayoutContext";
import {BreadcrumbsProvider} from "./BreadcrumbsContext";

const providers = [UserProvider, LayoutProvider, BreadcrumbsProvider];

export default function AppProvider(props) {
    const { children, ...rest } = props;
    return (
        <>
            {providers.reduceRight((acc, Comp) => {
                return <Comp {...rest}>{acc}</Comp>
            }, children)}
        </>
    )
}