let endpoint;
let debug;
let enviroment_name;
// let microsoft_client_id;
// let microsoft_tenant_url;
let key_google_maps;
let react_app_version;

if (process.env.REACT_APP_ENV === 'prod') {
    endpoint = 'https://api.pesquisa.codtechdevelopers.com.br/'
    enviroment_name = 'PROD';
    debug = false

} else if (process.env.REACT_APP_ENV === 'homo') {
    endpoint = 'https://stagingapiponto.layoutsistemas.com.br/'
    enviroment_name = 'HOMOLOGATION';
    debug = false

} else {
    endpoint = 'http://localhost:8000/'
    enviroment_name = 'LOCAL';
    debug = true
}
//
// if (process.env.REACT_APP_ENV === 'prod') {
//     microsoft_client_id = process.env.REACT_APP_MICROSOFT_APP_CLIENT_ID_PROD;
//     microsoft_tenant_url = `https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_TENANT_URL_PROD}`
//
// } else {
//     microsoft_client_id = process.env.REACT_APP_MICROSOFT_APP_CLIENT_ID_HOMO;
//      microsoft_tenant_url = `https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_TENANT_URL_HOMO}`
//
// }
//
if (process.env.REACT_APP_GOOGLE_MAPS_KEY) {
    key_google_maps = process.env.REACT_APP_GOOGLE_MAPS_KEY;
}

if (process.env.REACT_APP_VERSION) {
    react_app_version = process.env.REACT_APP_VERSION;
}

// export const MICROSOFT_APP_CLIENT_ID = microsoft_client_id
// export const MICROSOFT_APP_TENANT_URL = microsoft_tenant_url
export const GOOGLE_MAPS_KEY = key_google_maps
export const BASE_ENDPOINT = endpoint;
export const DEBUG = debug;
export const ENVIROMENT = enviroment_name;

export const APP_VERSION = react_app_version;
