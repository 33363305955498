import {useEffect, useState} from "react";
import api from "../services/api";
import {BuildFieldErrorsDict} from "../utils/Utils";


export default function useTable({
                                     request,
                                     hasPagination = true,
                                     fetchData,
                                     search = '',
                                     pageSize = 10,
                                     useHandleRequestTable = true,
                                     addLoad = true
                                 }) {
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState()
    const [order, setOrder] = useState()
    const [firstRequest, setFirstRequest] = useState(true);


    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: pageSize
        },
    });

    function resetTable() {
        handleTableChange({
            ...tableParams.pagination,
            current: 1
        })
    }


    function handleRequestTable() {
        if (firstRequest) {
            handleTableChange(tableParams.pagination, null, tableParams.sorter)
            setFirstRequest(false)
        } else {
            resetTable()
        }
    }

    useEffect(() => {
        if (useHandleRequestTable) {
            handleRequestTable()
        }

// eslint-disable-next-line
    }, [search])

    const handleTableChange = (pagination, filters, sorter, extra) => {
        let orderAux = '';
        let _order = order;
        if (sorter && sorter.order && Object.keys(sorter).length > 0) {
            orderAux = sorter.order === 'ascend' ? '' : '-'
            orderAux += sorter.column.sorter
            setOrder(orderAux)
            _order = orderAux
        } else if (sorter && sorter.order === undefined) {
            setOrder('')
            _order = orderAux
        }
        let fetchInfo = fetchData ? fetchData : fetchDataDefault
        fetchInfo({
            setIsLoading,
            pagination,
            order: _order,
            setTableParams,
            setTableData,
            firstRequest
        });

    };

    const fetchDataDefault = async ({
                                        setIsLoading,
                                        pagination,
                                        order,
                                        setTableParams,
                                        setTableData
                                    }) => {

        // Função de exemplo e fetch default
        try {
            setIsLoading(true)
            let queryParams = {}
            if (hasPagination) {
                queryParams.page_size = pagination.pageSize
                queryParams.page = pagination.current
            }
            if (order) {
                queryParams.ordering = order;
            }
            if (search) {
                queryParams.search = search;
            }


            if (request.extraParams) {
                queryParams = {...queryParams, ...request.extraParams}
            }
            let response;
            if (request.body) {
                response = await api.post(request.url, request.body, {params: queryParams});
            } else {
                response = await api.get(request.url, {params: queryParams});
            }

            let data, total;
            if (hasPagination) {
                data = response.data.results
                total = response.data.count
            } else {
                data = response.data
            }
            setTableData(data)
            setTableParams((tableParams) => {
                return {
                    ...tableParams,
                    pagination: {
                        ...pagination,
                        total: total
                    }
                }
            })
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false);
        }
    };


    return {
        isLoading,
        tableData,
        setTableData,
        tableParams,
        setTableParams,
        resetTable,
        handleTableChange,
        handleRequestTable
    }
}