import styled from "styled-components";



export const FlexContainer = styled.div`
  display: flex;
  align-items: ${({align}) => align ? align : 'center'};
  justify-content: ${({justify}) => justify ? justify : 'start'};
  gap: ${({gap}) => gap ? gap : 0}px;
  flex-direction: ${({direction}) => direction ? direction: 'row'} !important;
  flex-wrap: ${({wrap}) => wrap ? wrap: 'nowrap'} !important;
  
`


