import {useBreadcrumbsContext} from "../context/BreadcrumbsContext";
import {useEffect} from "react";

export default function useCreateBreadcrumbs(breadcrumbs, dependence=[]) {

    const {createBreadcrumbs} = useBreadcrumbsContext()

    useEffect(() => {//todo depois ver se da pra colocar isso dentro do contexto, passando somente a lista  pelo useBreadcrumbsContext
        createBreadcrumbs(breadcrumbs)
    }, [...dependence])
}