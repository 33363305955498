import React from 'react';
import { Spin } from 'antd';


export default function LoadingParent({loading=false, children, ...props}) {
    return (
        <Spin spinning={loading} {...props}>
            {children}
        </Spin>
    );
}
