import React, { useState} from 'react';
import {Col, Row, } from "antd";

import Map from "../../../components/data_display/Map";
import useDefaultGetRequest from "../../../hooks/useDefaultGetRequest";
import {formatPath} from "../../../utils/Utils";
import {API_PATHS} from "../../../constans/ApiPaths";
import Empty from "../../../components/data_display/Empty";
import DrawerIntreviewLocation from "./components/DrawerIntreviewLocation";
import Loading from "../../../components/feedback/Loading";

export default function TabSpotPoll({poll}) {
    const {
        data: interviews,
        isLoading: isLoading
    } = useDefaultGetRequest({
        url: formatPath(API_PATHS.POLL_INTERVIEWS_LOCATIONS, {id: poll.id}),
        dataDefault: []
    })

    const [currentMarker, setCurrentMarker] = useState()
    const [interview, setInterview] = useState()
    const [open, setOpen] = useState(false)

    function handleOnClose() {
        setOpen(false)
        setCurrentMarker(null)


    }

    if (isLoading) return <Loading/>
    if (interviews.length === 0) return <Empty/>

    const centerMAP = [interviews[0].location.latitude, interviews[0].location.longitude]


    return (
        <>
            <Row gutter={[16, 16]}>

                <Col xs={24}>
                    <Map
                        items={interviews}
                        center={centerMAP}
                        zoom={13}
                        currentMarker={currentMarker}
                        markerProps={{
                            click: (e) => {
                                setCurrentMarker(e)
                                setInterview(interviews.find(({id}) => id === e.target.options.id))
                                setOpen(e)

                            }
                        }}
                    />
                </Col>
                <DrawerIntreviewLocation poll={poll} onClose={handleOnClose} open={open}
                                         interview_id={interview?.id}/>
            </Row>
        </>
    );
}
