import React, {useState, createContext, useEffect, useContext} from 'react';

import api from "../services/api";
import {useNavigate} from "react-router-dom";
import {DEBUG} from "../settings";
import {
    deleteTokens, getClient,
    isAuthenticated, storeClient, storeclient,
} from "../utils/Auth";
import {BuildFieldErrorsDict} from "../utils/Utils";
import {API_PATHS} from "../constans/ApiPaths";
import {PATHS} from "../constans/Paths";
import {ToastNotification} from "../components/feedback/ToastNotification";

const UserContext = createContext();

function UserProvider({children}) {
    const [user, setUser] = useState(null);
    const [client, setClient] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [refreshContent, setRefreshContent] = useState(false);


    let navigate = useNavigate();

    function handleLogout() {
        deleteTokens()
        setClient(null)
        setUser(null)
        ToastNotification('Logout realizado com sucesso')
        navigate(PATHS.LOGIN)
    }

    const get_user = async () => {
        try {
            if (isAuthenticated()) {

                const response = await api.get(API_PATHS.AUTH_USER);

                if (response.data.is_active && response.data.clients.length > 0) {
                    setUser(response.data);
                } else {
                    // eslint-disable-next-line no-throw-literal
                    throw 'Usuário inativo'
                }
                // etUserClient(response.data[0].user_cliente_permission);
                // return response.data[0]
            }
        } catch (error) {
            BuildFieldErrorsDict(error, null, error)

            deleteTokens()
            navigate(PATHS.LOGIN)


        }
    }

    useEffect(() => {
        get_user()

    }, []);


    useEffect(() => {
        if (user) {
            if (user.clients.length === 0) {
                storeClient(user.clients[0].id)
                setClient(user.clients[0])
            } else {
                let _client = getClient()
                if (_client) {
                    setClient(user.clients.find(({id}) => id === Number(_client)))
                } else {
                    storeClient(user.clients[0].id)
                    setClient(user.clients[0])
                }
            }
        }

    }, [user]);

    const refreshUser = () => {
        setRefresh(true)
    }
    useEffect(() => {
        if (refresh === true) {
            get_user()
            setRefresh(false)
        }
    }, [refresh]);

    useEffect(() => {
        if (refreshContent === true) {
            // get_user()
            setRefreshContent(false)
        }
    }, [refreshContent]);


    function updateContent() {
        setRefreshContent(true)
    }

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                get_user,
                client,
                setClient,
                refreshContent,
                updateContent,
                refreshUser, handleLogout
            }}>
            {children}
        </UserContext.Provider>
    );
}

export {UserProvider};

export function useUserContext() {
    return useContext(UserContext);
}
