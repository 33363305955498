import React from "react";
import {Routes, Route} from "react-router-dom";
import {PrivateRoute} from "./PrivateRoute";
import LayoutSystem from "../layout/LayoutSystem";
import Login from "../pages/authentication/Login";
import {PATHS} from "../constans/Paths";
import PageListPoll from "../pages/poll/PageListPoll";
import PageUpdatePoll from "../pages/poll/PageUpdatePoll";
import PageCreatePoll from "../pages/poll/PageCreatePoll";
import PageListUser from "../pages/user/PageListUser";
import PageCreateUser from "../pages/user/PageCreateUser";
import PageUpdateUser from "../pages/user/PageUpdateUser";
import PageAddInterviewerPoll from "../pages/poll/PageAddInterviewerPoll";
import PageUpdateInterviewerPoll
    from "../pages/poll/PageUpdateInterviewerPoll";
import PageNotFound from "../pages/PageNotFound";


export default function RoutesSystem() {
    return (
        <Routes>
            <Route path={PATHS.LOGIN} exact element={<Login/>}/>
            <Route element={<PrivateRoute/>}>
                <Route path={PATHS.ROOT} element={<LayoutSystem/>}>
                    {/*<Route index element={<Home/>}/>*/}
                    {/*Roles*/}
                    <Route path={PATHS.POLL} element={<PageListPoll/>}/>
                    <Route path={PATHS.ROOT} element={<PageListPoll/>}/>
                    <Route path={PATHS.UPDATE_POLL}
                           element={<PageUpdatePoll/>}/>
                    <Route path={PATHS.CREATE_POLL}
                           element={<PageCreatePoll/>}/>
                    <Route path={PATHS.CREATE_INTERVIEWER}
                           element={<PageAddInterviewerPoll/>}/>
                    <Route path={PATHS.UPDATE_INTERVIEWER}
                           element={<PageUpdateInterviewerPoll/>}/>


                    <Route path={PATHS.USER} element={<PageListUser/>}/>
                    <Route path={PATHS.CREATE_USER}
                           element={<PageCreateUser/>}/>
                    <Route path={PATHS.UPDATE_USER}
                           element={<PageUpdateUser/>}/>

                </Route>
            </Route>
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};
