import React, {useState} from 'react';
import {Col, Row, Typography, Card, Pagination, Space} from "antd";

import {BuildFieldErrorsDict, formatPath} from "../../../utils/Utils";
import useDefaultGetRequest from "../../../hooks/useDefaultGetRequest";
import {API_PATHS} from "../../../constans/ApiPaths";
import Loading from "../../../components/feedback/Loading";
import styled from 'styled-components';
import Empty from "../../../components/data_display/Empty";
import DrawerIntreviewLocation from "./components/DrawerIntreviewLocation";
import {red} from "../../../utils/Colors";
import {CSVLink} from "react-csv";
import Button from "../../../components/data_entry/Button";
import {
    ExportOutlined,
    FilePdfOutlined,
    FundOutlined
} from "@ant-design/icons";
import {FlexContainer} from "../../../components/other/StyledComponents";
import api from "../../../services/api";


const StyledCard = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid black;
    cursor: pointer;

    ${({open}) => {
        if (open) {
            return `
            background: ${red};
            color: white;
            `
        }
    }};

    &:hover {
        background: ${red};
        color: white;
    }
`

const PAGE_SIZE = 10

export default function TabPollResponse({poll}) {
    const [interviewId, setInterviewId] = useState()
    const [open, setOpen] = useState(false)
    const [page, setPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const {
        response,
        data: results,
        isLoading: isLoadingResults
    } = useDefaultGetRequest({
        url: formatPath(API_PATHS.POLL_INTERVIEWS, {id: poll.id}),
        params: {page_size: PAGE_SIZE, page: page},
        hasPagination: true,
        dataDefault: [],
        dependence: [page]
    })

    function handleOnClose() {
        setOpen(false)
        setInterviewId(null)


    }

    function handleOpenDrawer(id) {
        setOpen(true)
        setInterviewId(id)


    }

     async function handleExportTable() {
        try {
            setIsLoading(true)
            const response = await api.get(formatPath(API_PATHS.POLL_PRINT_INTERVIEWS, {id: poll.id}), {
                responseType: "blob",
                // ...request
            });
            //
            const href = URL.createObjectURL(response.data);
            const tag_a = document.createElement('a');
            tag_a.href = href;
            tag_a.setAttribute('download', `${poll.title}-entrevistas.pdf`);
            tag_a.click();
            URL.revokeObjectURL(href);

        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        } finally {
            setIsLoading(false)
        }
    }

    if (isLoadingResults) return <Loading/>
    if (results.length == 0) return <Empty/>
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <FlexContainer justify={'space-between'}>
                        <Typography.Title
                            level={5}>Entrevistas</Typography.Title>

                        <Button loading={isLoading}  type={'default'}
                                onClick={handleExportTable}
                                icon={<FilePdfOutlined/>}>Exportar todas as entrevistas</Button>
                    </FlexContainer>


                </Col>
                {results.map(({id}, index) => (
                    <Col xs={12} sm={8} md={4} key={id}>
                        <StyledCard open={interviewId === id}
                                    onClick={() => handleOpenDrawer(id)}>
                            Entrevista: {(PAGE_SIZE * page) - results.length + index + 1}
                        </StyledCard>
                    </Col>
                ))}
                <Col xs={24}>
                    <Pagination hideOnSinglePage
                                onChange={(page) => setPage(page)}
                                current={page} pageSize={PAGE_SIZE}
                                showSizeChanger={false}
                                total={response.data.count} align={'center'}/>
                </Col>

            </Row>
            <DrawerIntreviewLocation poll={poll} onClose={handleOnClose}
                                     open={open}
                                     interview_id={interviewId}/>
        </>
    );
}
