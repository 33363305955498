import React, {useRef} from 'react';
import styled from 'styled-components';
import Empty from "../../../../components/data_display/Empty";

import QuestionEspontane from "./QuestionEspontane";
import QuestionInduziada from "./QuestionInduziada";
import {ESPONTANEA, INDUZIDA} from "../../../../constans/choices";


const _DragContainer = styled.div`


  & > .top {
    margin-top: 100px;
    box-shadow: 0px -25px 15px -3px rgba(0, 0, 0, 0.3) !important;
  }

  & > .bottom {
    margin-bottom: 100px;
    box-shadow: 0px 25px 15px -3px rgba(0, 0, 0, 0.3) !important;
  }
  padding: 24px;

`

export default function DragContainter({
                                           questions,
                                           setQuestions,
                                           onDragOver,
                                           recalculateSequence,
                                           onDrop,
                                           onDragEnd,
                                           onDragStart,
                                           ...props
                                       }) {
    const dragContainerRef = useRef()


    function onDelete(sequence) {
        const newQuestions = [...questions]
        newQuestions.splice(sequence - 1, 1)
        setQuestions(recalculateSequence(newQuestions))
    }

    function onClear(e) {


        dragContainerRef.current.querySelectorAll('[data-question-sequence]').forEach((e) => {
            e.classList.remove('top')
            e.classList.remove('bottom')
        })

    }

    function onChange(newData) {
        setQuestions((questions) => {
            return questions.map((question) => {
                if (question.sequence === newData.sequence) return newData
                return question
            })
        })
    }

    function getField({question,...props}) {

        switch (question.type) {
            case ESPONTANEA:
                return <QuestionEspontane  onDelete={onDelete} question={question} {...props}/>
            case INDUZIDA:
                return <QuestionInduziada onDelete={onDelete} question={question} {...props}/>

            default:
                return 'Falta implementar campo no FormBuild(MENSAGEM PARA DEVS)'
        }
    }


    function handleDragLeave(e) {
        if (!e.relatedTarget?.closest('[data-container-drop]')) onClear()
    }

    return (

        <_DragContainer data-container-drop ref={dragContainerRef} {...props}
                        onDragOver={onDragOver}
                        onDrop={(e) => {
                            onDrop?.(e)
                            onClear()
                        }} onDragLeave={handleDragLeave}>
            {questions.length === 0 && <Empty
                description={'Não existe perguntas no momento'}/>}
            {questions.length > 0 && questions.map((question) => getField({
                key: question.id || question.key,
                question,
                onDragEnd,
                onDragStart,
                onChange
            }))}
        </_DragContainer>

    );
}
