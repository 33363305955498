import React from 'react';
import {Col, Row, Typography} from "antd";
import Empty from "../../../../components/data_display/Empty";
import Bar from "../../../../components/data_display/graphics/Bar";
import Pie from "../../../../components/data_display/graphics/Pie";
import {ESPONTANEA} from "../../../../constans/choices";
import NormalizeQuestionResponseButton
    from "./NormalizeQuestionResponseButton";




export default function QuestionResult({
                                           poll, type,
                                           question,
                                           results,
                                           ...props
                                       }) {

    return (

        <Row gutter={[8, 8]} >

            <Col> <Typography.Title style={{marginBottom: 0}}
                                    level={4}>{question.sequence} - {question.statement} </Typography.Title> </Col>
            {question.type === ESPONTANEA &&  <Col xs={24}>
                <NormalizeQuestionResponseButton poll={poll} question={question} results={results}/>

            </Col>}
            {results.length > 0 ? <>
                {type === 'bar' && <Col xs={24}  style={{height: results.length < 15 ? 700: 2000}}>
                    <Bar poll={poll} data={results}  />
                </Col>}
                {type === 'pie' && <Col xs={24} style={{height: results.length < 15 ? 700: 2000}}>
                    <Pie poll={poll}  data={results} />
                </Col>}
            </>: <Col xs={24}><Empty/></Col>}

        </Row>


    )
}
