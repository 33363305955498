import React from 'react';
import styled from 'styled-components'
import { Col, Layout, Row } from "antd";
import LoginBG from '../../assests/img/bg_login.jpg';
import LogoImage from '../../assests/img/logo.png';
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {red} from "../../utils/Colors";


const ImageContainer = styled.div`
  display: block;
  //max-width: 100%;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: relative;


  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${red};
    z-index: 1;
    opacity: 0.6;
  }
`
const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Logo = styled.img`
  display: block;
  width: 130px;
  
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
`

const LogoCard = styled.img`
  display: block;
  width: 171px;
  margin: 0 auto;
  z-index: 2;
`

const StyledLayout = styled(Layout)`
  background: white;
`


const Container = styled.div`

  padding: 16px ${({isXs}) => isXs ? 16 : 32}px 16px;
  max-width: 500px;
  margin: 0 auto;
  height: 100%;
`

const Card = styled.div`
  //border: 1px solid black;
  border-color: rgba(219, 234, 254, 1);
  background-color: rgba(255, 255, 255, 1);
  padding: 16px;
  margin-top: 50px;
  border-radius: 8px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
`


const ScrollContainer = styled.div`
  overflow-y: auto;
  height: 100vh;

`

const ContainerForm = styled(Col)`
  flex: ${props => props.isLg ? '0 0 40%;' : 'auto;'}
  background: #F0F3F4;
`

export default function LayoutLogin({children}) {
    // const history = useHistory();
    const {lg, xs} = useBreakpoint()

    return (
        <>
            <StyledLayout>
                <Row style={{height: '100vh'}}>
                    <Col xs={0} lg={12}
                         style={{flex: '0 0 60%', maxWidth: 'none'}}>
                        <ImageContainer>
                            <Image src={LoginBG}/>
                            {/*<Logo src={CircularLogo}/>*/}
                        </ImageContainer>
                    </Col>
                    <ContainerForm isLg={lg} i>
                        <ScrollContainer>
                            <Container isXs={xs}>
                                <Card>
                                    <Row gutter={[8,8]}>
                                        <Col xs={24}>
                                            <LogoCard src={LogoImage}/>
                                        </Col>
                                        <Col xs={24}>
                                             {children}
                                        </Col>

                                    </Row>
                                </Card>
                            </Container>
                        </ScrollContainer>
                    </ContainerForm>
                </Row>
            </StyledLayout>

        </>

    );
}
