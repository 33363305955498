import React from 'react';
import InputField from "../InputField";
import FormItem from "./FormItem";
export default function InputFormField({form, ...props}) {
    return (
        <FormItem {...form}>
            < InputField
                {...props}
            />
        </FormItem>
    )
}