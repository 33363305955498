import {notification} from 'antd'
import {CloseOutlined} from "@ant-design/icons";
import styled from 'styled-components'
import {text_color} from "../../utils/Colors";
import './_feedback.css';
const Description = styled.span`
  white-space: pre-line;
`

export const ToastNotification = (description, type='success', title = '', icon = null) => {
    //success, error,info,warning
    notification[type](
        {
            message: title,
            description: <Description >{description}</Description>,
            closeIcon: <CloseOutlined style={{color: `${text_color}`}}/>,
            style: {color: `${text_color}`},
            // duration:99999
        }
    )
}