import React from 'react';
import { Space, Typography} from "antd";

import {DateFormat} from "../../../../utils/Utils";
import styled from 'styled-components'

const Text = styled(Typography.Text)`
    font-size: 18px;
`

export default function IntroductionResult({
                                               poll,
                                               ...props
                                           }) {


    return (
        <>
            {/*<Typography.Title*/}
            {/*    level={2}>{poll.client.title} </Typography.Title>*/}
            {/*<Typography.Title*/}
            {/*    level={4}>{poll.title} </Typography.Title>*/}
            {/*<Space size={16} direction={'vertical'}>*/}
            {/*    <Text>Pesquisa realizada*/}
            {/*        em {DateFormat(poll.start_date)}</Text>*/}
            {/*    <Text>{poll.amount_interviews} entrevista(s)*/}
            {/*        realizada(s)*/}
            {/*        de {poll.numbers_of_interviews}.</Text>*/}
            {/*    <Text> {poll.description}</Text>*/}
            {/*</Space>*/}
        </>


    )
}
