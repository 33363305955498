import React from 'react';
import {BrowserRouter} from "react-router-dom";
import AppProvider from "./context/compose";
import RoutesSystem from "./routes/Routes";
import {ConfigProvider} from "antd";
import {red} from "./utils/Colors";
import GlobalStyle from "./GlobalStyle";
import {locale} from "dayjs";

import ptBR from 'antd/locale/pt_BR'
const theme = {

    token: {
        colorPrimary: red,
        colorFillContent: red,

    },
}


export default function App() {
    locale('pt-br')

    return (
        <ConfigProvider theme={theme} locale={ptBR}>
            <GlobalStyle/>
            <BrowserRouter>
                <AppProvider>
                    <RoutesSystem/>
                </AppProvider>
            </BrowserRouter>

        </ConfigProvider>
    );
}
