import React from 'react';
import {Card, Col, Drawer, Row, Typography} from "antd";
import Button from "../../../../components/data_entry/Button";
import {GlobalOutlined} from '@ant-design/icons';
import useDefaultGetRequest from "../../../../hooks/useDefaultGetRequest";
import {formatPath} from "../../../../utils/Utils";
import {API_PATHS} from "../../../../constans/ApiPaths";
import Loading from "../../../../components/feedback/Loading";
import InputFormField
    from "../../../../components/data_entry/form_field/InputFormField";
import PasswordFormField
    from "../../../../components/data_entry/form_field/PasswordFormField";
import InputNumberFormField
    from "../../../../components/data_entry/form_field/InputNumberFormField";
import TextAreaFormField
    from "../../../../components/data_entry/form_field/TextAreaFormField";
import DatepickerFormField
    from "../../../../components/data_entry/form_field/DatepickerFormField";
import SelectFormField
    from "../../../../components/data_entry/form_field/SelectFormField";
import CheckboxFormField
    from "../../../../components/data_entry/form_field/CheckboxFormField";
import CheckboxGroupFormField
    from "../../../../components/data_entry/form_field/CheckboxGroupFormField";
import LocationMapFormField
    from "../../../../components/data_entry/form_field/LocationMapFormField";
import AutoCompleteFormField
    from "../../../../components/data_entry/form_field/AutoCompleteFormField";
import InputSelectTableFormField
    from "../../../../components/data_entry/form_field/InputSelectTableFormField";
import SwitchFormField
    from "../../../../components/data_entry/form_field/SwitchFormField";
import FormItem from "../../../../components/data_entry/form_field/FormItem";
import {
    FIELDS_NAMES
} from "../../../../components/data_entry/form_field/FormBuild";
import {ESPONTANEA, INDUZIDA} from "../../../../constans/choices";
import QuestionEspontane from "./QuestionEspontane";
import QuestionInduziada from "./QuestionInduziada";
import {FlexContainer} from "../../../../components/other/StyledComponents";

export default function DrawerIntreviewLocation({
                                                    interview_id,
                                                    open,
                                                    onClose,
                                                    poll
                                                }) {

    const {
        data: interview,
        isLoading: isLoading
    } = useDefaultGetRequest({
        url: formatPath(API_PATHS.INTERVIEWS_DETAIL, {id: interview_id}),
        makeRequest: !!interview_id,
        dependence: [interview_id],
        // dataDefault: {}
    })

    function FACTORY_RESPONSE({question, ...response}) {

        switch (question.type) {
            case ESPONTANEA:
                return <Card
                    title={`${question.sequence} - ${question.statement}`}>
                    <FlexContainer gap={4} direction={'column'} align={'start'}>
                        <Typography.Text
                            style={{fontSize: 18}}>
                            <Typography.Text
                                strong>Resposta:</Typography.Text> {response.open_field}
                        </Typography.Text>
                        {response.is_normalized && <Typography.Text type={'secondary'}
                            style={{fontSize: 14}}>
                            <Typography.Text type={'secondary'}
                                strong>Resposta original:</Typography.Text> {response.old_open_field}
                        </Typography.Text>}
                    </FlexContainer>

                </Card>

            case INDUZIDA:
                return <Card
                    title={`${question.sequence} - ${question.statement}`}>
                    <Typography.Text
                        style={{fontSize: 18}}><Typography.Text
                        strong>Resposta:</Typography.Text> {response.chosen_alternative.statement}
                    </Typography.Text>
                </Card>


            default:
                return 'Falta implementar campo no FormBuild(MENSAGEM PARA DEVS)'
        }
    }

    return (

        <Drawer width={700} title={<Typography.Title
            level={3}>Respostas da entrevista</Typography.Title>} open={open}
                onClose={onClose}
                extra={
                    poll?.has_location && <Button disabled={isLoading}
                                                  href={`https://www.google.com/maps/search/?api=1&query=${interview?.location?.latitude},${interview?.location?.longitude}`}
                                                  target={'_blank'}
                                                  type={'link'}
                                                  icon={<GlobalOutlined/>}>Abrir
                        no google maps</Button>
                }>
            {isLoading && <Loading/>}
            <Row gutter={[20, 20]}>
                {interview?.responses?.map((item) => (
                    <Col xs={24}>{FACTORY_RESPONSE(item)}</Col>))}
            </Row>
        </Drawer>

    );
}
