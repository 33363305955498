import React, {useState} from 'react';
import {
    Col,
    Divider,
    Form as FormAnt, Modal,
    Popconfirm,
    Row,
    Typography
} from "antd";
import {PATHS, TAB_POLL_PATH} from "../../constans/Paths";
import useCreateBreadcrumbs from "../../hooks/useCreateBreadcrumbs";
import Button from "../../components/data_entry/Button";
import FormBuild, {
    FIELDS_NAMES
} from "../../components/data_entry/form_field/FormBuild";
import {
    BuildFieldErrorsDict,
    formatPath,
    generateFieldsByColumns
} from "../../utils/Utils";
import api from "../../services/api";
import {ToastNotification} from "../../components/feedback/ToastNotification";
import {useNavigate, useParams} from "react-router-dom";
import usePollForm from "./hooks/usePollForm";
import {API_PATHS} from "../../constans/ApiPaths";
import {PlusOutlined} from "@ant-design/icons";
import useDefaultGetRequest from "../../hooks/useDefaultGetRequest";


const FORM_NAMES = {
    NUMBER_OF_INTERVIEWS: 'number_of_interviews',
    INTERVIEWER: 'interviewer',
}

export default function PageAddInterviewerPoll() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [form] = FormAnt.useForm()
    const {id, id_interviewer} = useParams()
    const {
        data: poll,
        isLoading: isLoadingPoll
    } = useDefaultGetRequest({
        url: formatPath(API_PATHS.POLL_DETAIL, {id: id}),
    })



    const PATH_LIST_INTEVIEWERS = formatPath(PATHS.UPDATE_POLL, {id: poll.id}, {tab: 'interviewers_allocation'})
    const breadcrumbs = [
         {
            title: 'Pesquisas',
            link: PATHS.POLL
        },
        {
            title: 'Entrevistadores',
            link: PATH_LIST_INTEVIEWERS
        },
        {
            title: 'Adicionar novo entrevistador',
        },

    ]
    useCreateBreadcrumbs(breadcrumbs, [poll])

    async function handleSubmit() {
        try {
            setIsLoading(true)
            let data = await form.getFieldsValue()
            data['poll'] = poll.id
            data['interviewer_id'] = data[FORM_NAMES.INTERVIEWER].id
            const response = await api.post(API_PATHS.INTERVIEWER, data);
            ToastNotification('Entrevistador adicionado com sucesso')
            navigate(PATH_LIST_INTEVIEWERS)

        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, false)
        } finally {
            setIsLoading(false);
        }
    }

    const columns_interviewer = [
        {
            title: 'Nome',
            dataIndex: 'get_full_name',
            sorter: 'get_full_name',
            key: 'get_full_name',
        },

    ]

    const FORM_FIELDS = [
        {
            field_type: FIELDS_NAMES.INPUT_NUMBER,
            col: {xs: 24},
            min: 1,
            max: poll.amount_interviews_remaining_for_distribution,
            form: {
                label: `Quantidade de entrevistas - (Quantidade de entrevistas disponiveis: ${poll.amount_interviews_remaining_for_distribution})`,
                name: FORM_NAMES.NUMBER_OF_INTERVIEWS,
                rules: [{
                    required: true,
                    message: 'Quantidade de entrevistas é obrigatório'
                }]
            },
        },
        {
            field_type: FIELDS_NAMES.INPUT_SELECT_TABLE,
            columns: columns_interviewer,
            col: {xs: 24},
            request: {

                url: API_PATHS.USERS_POST,
                extraParams: {

                    fields: `${generateFieldsByColumns(columns_interviewer)},id`
                },
                body:{
                     user_permissions__codename__in: ['interview'],
                }
            },
            description_label: 'get_full_name',
            form: {
                label: 'Entrevistador',
                name: FORM_NAMES.INTERVIEWER,
                rules: [{
                    required: true,
                    message: 'Entrevistador é obrigatório'
                }]

            },
        },]

    return (

        <>
            <Typography.Title>Adicionar novo entrevistador</Typography.Title>
            <Divider/>

            <FormBuild loading={isLoadingPoll || isLoading}
                       onFinish={handleSubmit}
                // onValuesChange={handleFields}
                       fields={FORM_FIELDS} form={form}
                       footer={
                           <Row justify={'end'} gutter={[8, 8]}>

                               <Col>

                                   <Popconfirm
                                       title={`Cancelar cadastro`}
                                       description="Tem certeza que deseja realizar está ação?"
                                       onConfirm={() => navigate(PATH_LIST_INTEVIEWERS)}
                                       okText="Sim"
                                       okButtonProps={{loading: isLoading}}
                                       cancelText="Cancelar"
                                   >
                                       <Button loading={isLoading}
                                               type={"default"}>Cancelar</Button>
                                   </Popconfirm>

                               </Col>
                               <Col>
                                   <Button loading={isLoading}
                                           htmlType="submit">Salvar</Button>
                               </Col>
                           </Row>
                       }/>

        </>


    )
}
