import React from 'react';
import FormItem from "./FormItem";
import Switch from "../Switch";

export default function SwitchFormField({form, ...props}) {
    return (
        <FormItem valuePropName={"checked"}  {...form} >

            <Switch

                title={form.label}
                {...props}
            />
        </FormItem>
    )
}