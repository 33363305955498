import React, {useState, createContext, useEffect, useContext} from 'react';
import {NavLink, useLocation} from "react-router-dom";

const BreadcrumbsContext = createContext();

export function BreadcrumbsProvider({children}) {
    const [breadcrumbs, setBreadcrumns] = useState([]);
    const location = useLocation()

    useEffect(() => { // limpa breadcrumbs
        setBreadcrumns([]) // todo o F5 está gerando o desaparecimento do breadcrumns pq esse useEffect é chamado por ultimo, resolver depois
    }, [location.pathname])

    function createBreadcrumbs(items) {
        const newItems = items.map((item) => {
            if (item.link) {
                return {title: <NavLink to={item.link}>{item.title}</NavLink>}
            }
            return item
        })
        setBreadcrumns(newItems)
    }

    return (
        <BreadcrumbsContext.Provider
            value={{breadcrumbs, createBreadcrumbs}}>
            {children}
        </BreadcrumbsContext.Provider>
    );
}


export function useBreadcrumbsContext() {
    return useContext(BreadcrumbsContext);
}
