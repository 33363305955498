import React, {useMemo, useState} from 'react';
import {Modal, Form as FormAnt, Row, Col, Form, Typography} from "antd";
import FormBuild, {
    FIELDS_NAMES
} from "../../../../components/data_entry/form_field/FormBuild";
import {
    BuildFieldErrorsDict, formatPath,
    generateSelectOptions
} from "../../../../utils/Utils";
import {BAR, GRAPHICS_PRINT_CHOICES} from "../../../../constans/choices";
import {Margin, usePDF} from "react-to-pdf";
import styled from 'styled-components'
import Bar from "../../../../components/data_display/graphics/Bar";
import Pie from "../../../../components/data_display/graphics/Pie";
import {FlexContainer} from "../../../../components/other/StyledComponents";
import Button from "../../../../components/data_entry/Button";
import {EditOutlined, ExclamationCircleFilled} from "@ant-design/icons";
import InputFormField
    from "../../../../components/data_entry/form_field/InputFormField";
import api from "../../../../services/api";
import {API_PATHS} from "../../../../constans/ApiPaths";
import {
    ToastNotification
} from "../../../../components/feedback/ToastNotification";
import {PATHS} from "../../../../constans/Paths";

const FORM_NAMES = {
    GRAPHICS: 'graphics',
    HAS_TABLE: 'has_table',
    HAS_INTRODUCTION: 'has_introduction',

}

const Page = styled.div`
    width: 210mm;
    height: 297mm !important;
    padding: 16px 20px;
`

const Introduction = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;

    span {
        font-size: 24px;
    }


`

export default function NormalizeQuestionResponseButton({
                                                            poll,
                                                            results,
                                                            question,

                                                        }) {
    const [form] = FormAnt.useForm()
    const [open, setOpen] = useState()
    const [isLoading, setIsLoading] = useState(false)

    function onClose() {
        setOpen(false)
        form.resetFields()
    }

    async function handleSubmit(data) {
        Modal.confirm({
            title: 'Realmente deseja realizar essa alteração??',
            icon: <ExclamationCircleFilled/>,
            content: 'depois de confirmado, a alteração não pode ser desfeita.',
            async onOk() {
                try {
                    setIsLoading(true)

                    const response = await api.post(formatPath(API_PATHS.POLL_QUESTION_RESPONSE, {id: poll.id}), {question: question.id, ...data});

                    ToastNotification('Resposta corrigida com sucesso!')
                    setTimeout(() => {
                        onClose()
                        window.location.reload()
                    }, 1000)


                    // ToastNotification(response.data.detail)
                    // navigate(PATHS.LOGIN)

                } catch (error) {
                    BuildFieldErrorsDict(error, form.setFields, false)
                } finally {
                    setIsLoading(false);
                }
            }
        });

    }

    return (
        <>
        {results.length > 0 &&  <Button icon={<EditOutlined/>} type={'default'}
                    onClick={() => setOpen(true)}>Correção de
                respostas</Button>}
            <Modal
                open={open}
                title={`Correção de
                respostas - ${question.statement}`}
                width={800}
                // okButtonProps={{
                //     loading: isLoading
                // }}
                okText={'Salvar'}
                onOk={() => {
                    form.submit()
                }}
                onCancel={onClose}

            >
                <Form layout={'horizontal'} onFinish={handleSubmit}
                      form={form}>
                    <Row>
                        {results.map((result) => (
                            <Col xs={24}>
                                <InputFormField form={{
                                    name: result.statement,
                                    label: result.statement,
                                    initialValue: result.statement
                                }}/>
                            </Col>
                        ))}


                    </Row>
                </Form>


            </Modal>
        </>


    )
        ;
}
