import React from 'react';
import useLayoutContent from "../../context/LayoutContext";
import styled from "styled-components";
import {MenuUnfoldOutlined, MenuFoldOutlined} from '@ant-design/icons';


const Container = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  & > * {
    font-size: 20px;
    color: white;
  }
`

export default function CollapsedMenuButton(props){
    const {changeCollpasedButton, collapsed} = useLayoutContent();
    return (
        <Container onClick={changeCollpasedButton} {...props}>
            {collapsed?  <MenuUnfoldOutlined/>: <MenuFoldOutlined/>}
        </Container>
    )
}