import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {isAuthenticated} from '../utils/Auth';
import {PATHS} from "../constans/Paths";
import useQuery from "../hooks/useQuery";
import {useEffect} from "react";

export const PrivateRoute = (props) => {
    const authenticated = isAuthenticated()
    const location = useLocation()
    const querys = useQuery();
    const navigate = useNavigate()

    useEffect(() => {
        if (!authenticated) {
            const pathname = location.pathname
            querys.set('next', pathname)
            navigate({pathname: PATHS.LOGIN, search: querys.toString()})
        }
    }, [])

    return authenticated ? <Outlet/> : null


}
