import React from 'react';
import {DatePicker as DatepickerAnt} from "antd";
import styled from 'styled-components';

const StyledDatepickerAnt = styled(DatepickerAnt)`
  width: 100%;
`

export default function Datepicker({defaultValue, ...props}) {


    return (
        <StyledDatepickerAnt showTime={false} defaultValue={defaultValue} format={'DD/MM/YYYY'} {...props} />
    )
}