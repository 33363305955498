import React from 'react';
import PasswordChecklist from 'react-password-checklist';
import styled from 'styled-components';

const StyledPasswordChecklist = styled(PasswordChecklist)`
  & > li {
    display: flex;
    align-items: center;

  }
`

export default function PasswordCheckList({
                                              password,
                                              confirmPassword,
                                              ...props
                                          }) {

    return (
        <StyledPasswordChecklist
            rules={["minLength", "specialChar", "number", "capital", 'lowercase', "match"]}
            minLength={8}
            iconSize={12}


            messages={{
                minLength: " A senha tem que ter no mínimo 8 caracteres",
                specialChar: "A senha tem que ter no mínimo um caractere especial.",
                number: "A senha tem que ter no mínimo um número.",
                capital: "A senha tem que ter no mínimo uma letra maiúscula.",
                lowercase: "A senha tem que ter no mínimo uma letra minúscula.",
                match: "A senha e o confirma senha tem que ser iguais",
            }}
            {...props}
            value={password || ''}
            valueAgain={confirmPassword || ''}
        />
    )
}